import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CnMessage, Form, CnInput, CnCard, CnDialog } from '@alife/cn-ui';
import { v4 as uuidv4 } from 'uuid';
import { initData } from '../../const';
import cnRequest from 'src/api';
import { formatDetailTableColumns, formatDynamicTableColumns } from './utils';
import DetailTableComponent from './detail-table';
import Filter from './filter';
import DynamicTable from './dynamic-table';
export default function Step2({ id, isAddMode, visible, setVisible, setNewId, }) {
    const [dynamicSelected, setDynamicSelected] = useState([]);
    const [data, setData] = useState(initData);
    const dataRef = useRef(null);
    const [dynamicTableLoading, setDynamicTableLoading] = useState(false);
    const [detailTableLoading, setDetailTableLoading] = useState(false);
    const lastDynamicSortRef = useRef(null);
    useEffect(() => {
        dataRef.current = data;
    }, [data]);
    // 初始化
    useEffect(() => {
        if (!id || !visible) {
            return;
        }
        cnRequest({
            url: '/api/move/get',
            method: 'POST',
            data: { mvOrderId: id },
        }).then((res) => {
            const { data: resData, success, error, } = res;
            const { detailHead = {}, tab = {} } = resData;
            const { table = {} } = tab;
            const { headers = [], items = [], paging = {} } = table;
            let ownerId;
            let warehouseAreaId;
            const tmpInfoData = {};
            if (success) {
                if (detailHead?.items && Array.isArray(detailHead?.items)) {
                    // this.stepOneFieldCache = Object.create(null);
                    detailHead.items.forEach((head) => {
                        if (head?.key === 'ownerId') {
                            ownerId = head?.value;
                        }
                        else if (head?.key === 'fromWarehouseAreaId') {
                            warehouseAreaId = head?.value;
                        }
                        else if (head?.key === 'type') {
                            tmpInfoData.type =
                                head?.value === 1
                                    ? $i18n.get({ id: 'OneStepShift', dm: '一步移位' })
                                    : $i18n.get({ id: 'TwostepShift', dm: '两步移位' });
                        }
                        else if (head?.key === 'code') {
                            tmpInfoData.orderCode = head?.value;
                        }
                        else if (head?.key === 'ownerName') {
                            tmpInfoData.ownerName = head?.value || '';
                        }
                        else if (head?.key === 'fromWarehouseAreaName') {
                            tmpInfoData.fromSectionName = head?.value;
                        }
                        else if (head?.key === 'toWarehouseAreaName') {
                            tmpInfoData.toSectionName = head?.value;
                        }
                        else if (head?.key === 'remark') {
                            tmpInfoData.remark = head?.value || '';
                        }
                    });
                    setData({
                        ...(dataRef?.current || {}),
                        detailTable: {
                            items,
                            headers: formatDetailTableColumns(headers),
                            paging: {
                                currentPage: 1,
                                pageSize: 10,
                                totalItems: 0,
                                ...paging,
                            },
                        },
                        infoData: tmpInfoData,
                        filterData: {
                            ownerId,
                            warehouseAreaIdList: warehouseAreaId ? [warehouseAreaId] : [],
                        },
                    });
                }
            }
            else {
                CnMessage.error(error?.message || error?.code || $i18n.get({ id: 'UnknownException', dm: '未知异常' }));
            }
        });
    }, [id, visible]);
    useEffect(() => {
        cnRequest({
            url: '/api/move/inv/getItemBatchField',
            method: 'POST',
            data: { ownerId: data?.filterData?.ownerId },
        }).then((response) => {
            const tmpData = {
                ...(dataRef?.current || {}),
                itemBatchFieldList: response.data,
            };
            setData(tmpData);
        });
    }, [id, data?.filterData?.ownerId]);
    const loadDetail = useCallback((paramsPaging) => {
        setDetailTableLoading(true);
        cnRequest({
            url: '/api/move/detail/list',
            method: 'POST',
            data: {
                mvOrderId: id,
                paging: {
                    ...(data.detailTable?.paging || {}),
                    ...paramsPaging,
                },
            },
        })
            .then((res) => {
            const { data: resData, success, error, } = res;
            const { headers = [], items = [], paging = {} } = resData;
            if (success) {
                const tmpPaging = {
                    ...(data.detailTable?.paging || {}),
                    ...(paging || {}),
                    currentPage: paramsPaging?.currentPage || 1,
                };
                const tmpData = {
                    ...dataRef.current,
                    detailTable: {
                        items: items?.map((item) => {
                            return {
                                ...item,
                                _uid: uuidv4(),
                            };
                        }),
                        headers: formatDetailTableColumns(headers),
                        paging: tmpPaging,
                    },
                };
                setData(tmpData);
            }
            else {
                CnMessage.error(error?.message ||
                    error?.code ||
                    $i18n.get({ id: 'UnknownException', dm: '未知异常' }));
            }
        })
            .finally(() => {
            setDetailTableLoading(false);
        });
    }, [id, data]);
    const handleUpdatePlanQuantity = useCallback((v, uid, type) => {
        const { dynamicTable } = dataRef?.current || {};
        const inventoryDataSource = dynamicTable?.items?.map((row) => {
            const { _uid } = row;
            if (uid === _uid) {
                return {
                    ...row,
                    planQuantity: {
                        ...(row.planQuantity || {}),
                        [type]: v,
                    },
                };
            }
            return row;
        });
        setData({
            ...(dataRef?.current || {}),
            dynamicTable: {
                ...dynamicTable,
                items: inventoryDataSource,
            },
        });
    }, []);
    const handleUpdateToCabinetId = useCallback((v, uid) => {
        const { dynamicTable } = dataRef?.current || {};
        const inventoryDataSource = dynamicTable?.items?.map((row) => {
            const { _uid } = row;
            if (uid === _uid) {
                return {
                    ...row,
                    toCabinetCode: v,
                };
            }
            return row;
        });
        setData({
            ...(dataRef?.current || {}),
            dynamicTable: {
                ...dynamicTable,
                items: inventoryDataSource,
            },
        });
    }, []);
    const handleQuery = useCallback((resetPage = true, sortCode, order) => {
        setDynamicTableLoading(true);
        const { filterData, dynamicTable } = dataRef.current || {};
        const params = {
            ...filterData,
            paging: dynamicTable?.paging || { currentPage: 1, pageSize: 10 },
            cabinetCodeList: filterData?.cabinetCodeList ? filterData?.cabinetCodeList?.split(',') : [],
            itemBarcodeList: filterData?.itemBarcodeList ? filterData?.itemBarcodeList?.split(',') : [],
            itemBatchCodeList: filterData?.itemBatchCodeList
                ? filterData?.itemBatchCodeList.split(',')
                : [],
        };
        if (filterData?.produceDate) {
            params.produceDateBegin = filterData?.produceDate[0];
            params.produceDateEnd = filterData?.produceDate[1];
        }
        if (filterData?.expiryDate) {
            params.expiryDateBegin = filterData?.expiryDate[0];
            params.expiryDateEnd = filterData?.expiryDate[1];
        }
        if ((sortCode && order) ||
            (lastDynamicSortRef.current?.order && lastDynamicSortRef.current?.sortCode)) {
            const sortField = sortCode || lastDynamicSortRef.current?.sortCode;
            const sortMode = (order || lastDynamicSortRef.current?.order || '').toUpperCase() === 'ASC'
                ? 'ASC'
                : 'DESC';
            params.sortMode = sortMode;
            params.sortField = sortField;
            lastDynamicSortRef.current = {
                sortCode: sortField,
                order: sortMode,
            };
        }
        else {
            lastDynamicSortRef.current = null;
        }
        cnRequest({
            url: '/api/move/inv/list',
            method: 'POST',
            data: params,
        })
            .then((res) => {
            const { data: resData, success, error, } = res;
            const { paging = {}, headers = [], items = [] } = resData;
            if (success) {
                if (Array.isArray(headers)) {
                    headers.splice(headers.length - 2, 0, {
                        key: 'planQuantity',
                        name: $i18n.get({
                            id: 'PlannedShiftNumber',
                            dm: '计划移位数',
                        }),
                    }, {
                        key: 'toCabinetCode',
                        name: $i18n.get({
                            id: 'PlannedShelfStorageLocation',
                            dm: '计划上架库位',
                        }),
                    });
                }
                setData({
                    ...dataRef.current,
                    dynamicTable: {
                        headers: formatDynamicTableColumns(headers, handleUpdatePlanQuantity, handleUpdateToCabinetId),
                        items: items?.map((item) => {
                            return {
                                ...item,
                                _uid: uuidv4(),
                                planQuantity: {
                                    box: 0,
                                    piece: 0,
                                },
                            };
                        }),
                        paging: {
                            ...paging,
                            currentPage: resetPage ? 1 : dynamicTable?.paging?.currentPage || 1,
                            pageSize: dynamicTable?.paging?.pageSize || 10,
                        },
                    },
                });
            }
            else {
                CnMessage.error(error?.message ||
                    error?.code ||
                    $i18n.get({ id: 'UnknownException', dm: '未知异常' }));
            }
        })
            .finally(() => {
            setDynamicTableLoading(false);
        });
    }, [handleUpdatePlanQuantity, handleUpdateToCabinetId]);
    const handleClose = useCallback(() => {
        setVisible(false);
        if (isAddMode) {
            setNewId(null);
        }
    }, [isAddMode, setVisible, setNewId]);
    return (React.createElement(CnDialog, { title: isAddMode
            ? $i18n.get({ id: 'NewShiftOrder', dm: '新建移位单' })
            : $i18n.get({ id: 'EditShiftList', dm: '编辑移位单' }), visible: visible, size: "large", className: "biz-move-location-dialog-step2", popupContainer: document.querySelector('body'), onClose: () => handleClose(), footer: false },
        React.createElement(Form, { className: "biz-move-location-dialog-info", value: data.infoData, isPreview: true, labelAlign: "left" },
            React.createElement(Form.Item, { label: $i18n.get({ id: 'ShiftNumber', dm: '移位单号:' }) },
                React.createElement(CnInput, { name: "orderCode" })),
            React.createElement(Form.Item, { label: $i18n.get({ id: 'ShiftType', dm: '移位类型:' }) },
                React.createElement(CnInput, { name: "type" })),
            data.infoData?.ownerName && (React.createElement(Form.Item, { label: $i18n.get({ id: 'Owner', dm: '货主:' }) },
                React.createElement(CnInput, { name: "ownerName" }))),
            data.infoData?.fromSectionName && (React.createElement(Form.Item, { label: $i18n.get({ id: 'SourceReservoirArea', dm: '来源库区:' }) },
                React.createElement(CnInput, { name: "fromSectionName" }))),
            data.infoData?.toSectionName && (React.createElement(Form.Item, { label: $i18n.get({ id: 'TargetReservoirArea', dm: '目标库区:' }) },
                React.createElement(CnInput, { name: "toSectionName" }))),
            data.infoData?.remark && (React.createElement(Form.Item, { label: $i18n.get({ id: 'Remarks', dm: '备注:' }) },
                React.createElement(CnInput, { name: "remark" })))),
        React.createElement(Filter, { data: data, setData: setData, dataRef: dataRef, handleQuery: handleQuery }),
        React.createElement(CnCard, { title: $i18n.get({ id: 'Inventory', dm: '库存' }), style: { marginTop: 12 } },
            React.createElement(DynamicTable, { loadDetail: loadDetail, dynamicTableLoading: dynamicTableLoading, id: id, data: data, setData: setData, dataRef: dataRef, dynamicSelected: dynamicSelected, setDynamicSelected: setDynamicSelected, handleQuery: handleQuery })),
        React.createElement(CnCard, { title: $i18n.get({ id: 'ScheduleShiftDetails', dm: '计划移位明细' }), style: { marginTop: 12 } },
            React.createElement(DetailTableComponent, { data: data, id: id, loadDetail: loadDetail, detailTableLoading: detailTableLoading }))));
}
