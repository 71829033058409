import $i18n from '@alife/panda-i18n';
import React from 'react';
import { CnBalloon, CnInput, CnNumberPicker } from '@alife/cn-ui';
import { INVENTORY_TABLE_COL_LOCK } from '../../const';
export const formatDetailTableColumns = function (headers) {
    return (headers
        ?.filter((header) => header?.enable !== false)
        ?.map((header) => {
        if (['planQuantity', 'actualQuantity'].includes(header?.key)) {
            return {
                ...header,
                cell: (value, __index, record) => {
                    const boxNum = Math.floor(value / record.boxRegulation);
                    const pieceNum = value % record.boxRegulation;
                    return (React.createElement("span", null, value && record.boxRegulation
                        ? $i18n.get({
                            id: 'BoxNumBoxPieceNum',
                            dm: '{boxNum}箱{pieceNum}个',
                        }, { boxNum, pieceNum })
                        : ''));
                },
            };
        }
        if (header.key === 'ownerName' ||
            header.key === 'itemBarCode' ||
            header.key === 'itemName' ||
            header.key === 'itemBatchCode') {
            return {
                ...header,
                cell: (value) => (React.createElement(CnBalloon.Tooltip, { trigger: React.createElement("span", { className: "inventory-table-cell" }, value), triggerType: "hover", title: `${value}` }, value)),
            };
        }
        return {
            ...header,
        };
    }) || []);
};
export const formatDynamicTableColumns = function (headers, handleUpdatePlanQuantity, handleUpdateToCabinetId) {
    return headers
        ?.filter((header) => typeof header.enable === 'undefined' || !!header.enable)
        ?.map((header) => {
        const tmpHeader = {
            ...header,
        };
        const { key } = header;
        delete tmpHeader.width;
        if (key === 'planQuantity') {
            tmpHeader.width = 200;
            tmpHeader.cell = (value, _unuse_index, record) => {
                const { warehouseAreaTaskStyle, availableInvQuantity, _addToDetail, boxRegulation, _uid, } = record;
                if (warehouseAreaTaskStyle === 5) {
                    return availableInvQuantity;
                }
                const total = typeof value === 'object'
                    ? (value?.box || 0) * (boxRegulation || 0) + (value?.piece || 0)
                    : '';
                return (React.createElement("div", null,
                    React.createElement(CnNumberPicker, { value: value?.box || '', min: 0, style: { width: '60px' }, disabled: _addToDetail || !boxRegulation, onChange: (v) => handleUpdatePlanQuantity(v, _uid, 'box') }),
                    React.createElement("span", { style: { padding: '0 4px' } }, $i18n.get({ id: 'Box', dm: '箱' })),
                    React.createElement(CnNumberPicker, { min: 0, value: value?.piece || '', style: { width: '60px' }, disabled: _addToDetail, onChange: (v) => handleUpdatePlanQuantity(v, _uid, 'piece') }),
                    React.createElement("span", { style: { padding: '0 4px' } }, $i18n.get({ id: 'A', dm: '个' })),
                    "= ",
                    total));
            };
        }
        if (key === 'toCabinetCode') {
            tmpHeader.cell = (value, _unuse_index, record) => {
                const { _uid } = record;
                return (React.createElement(CnInput, { style: { width: '100%' }, value: value, onChange: (input) => handleUpdateToCabinetId(input, _uid) }));
            };
        }
        if (['ownerName', 'itemBarCode', 'itemName', 'itemBatchCode'].includes(key)) {
            tmpHeader.cell = (value) => {
                return (React.createElement(CnBalloon, { trigger: React.createElement("span", { className: "inventory-table-cell" }, value), triggerType: "hover", title: value }, value));
            };
        }
        return {
            ...tmpHeader,
            lock: INVENTORY_TABLE_COL_LOCK[key] || undefined,
            sortable: ['produceDate', 'expiryDate', 'realInventory', 'availableInvQuantity'].includes(key),
        };
    });
};
