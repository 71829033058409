import './wrapper.scss';

import React from 'react';
import classNames from 'classnames';

export default function Wrapper({ children, center, style }) {
  return (
    <div className={classNames('habbit-wrapper', center && 'center')} style={style}>
      {children}
    </div>
  );
}
