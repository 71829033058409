import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { CnAsyncSelect, CnCheckbox, Form, Field, CnMessage } from '@alife/cn-ui';
import omit from 'lodash/omit';
import cnRequest from 'src/api';
import { componentName } from './const';
import { DynamicInputs } from './components/dynamic-Inputs';
import './index.scss';
const defaultValue = {
    outbound: false,
    ruleList: [
        {
            ruleItemList: [{ matchField: null, matchType: null, matchValue: null }],
            priority: 2,
        },
    ],
};
function SorterRule(props) {
    const { className = '', _context = {}, _buttonPosition = '', _dialogRef } = props;
    const { state = {} } = _context;
    const { CnDialog4 = {}, urlParams = {} } = state;
    const { id } = CnDialog4;
    const { id: urlId } = urlParams;
    const field = Field.useField();
    const [value, setValue] = useState({
        ...defaultValue,
    });
    const [matchDatasource, setMatchDatasource] = useState([]);
    const [priorityDatasource, setPriorityDatasource] = useState([]);
    const [chuTeOptionDatasource, setChuTeOptionDatasource] = useState([]);
    const [projectId, setProjectId] = useState(null);
    const isAddMode = useMemo(() => {
        return _buttonPosition === 'btn_tableToolArea';
    }, [_buttonPosition]);
    const getMactDatasource = useCallback(() => {
        cnRequest({
            url: '/api/chute_rule/match_field_options',
            method: 'GET',
        }).then((res) => {
            const options = res?.data || [];
            if (options) {
                setMatchDatasource(options);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    const getPriorityDatasource = useCallback(() => {
        cnRequest({
            url: '/api/chute_rule/rule_priority_options',
            method: 'GET',
        }).then((res) => {
            const options = res?.data || [];
            if (options) {
                setPriorityDatasource(options);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    const getChuTeOption = useCallback((chuteId) => {
        cnRequest({
            url: '/api/chute_rule/left_chute_options',
            method: 'GET',
            params: {
                id: chuteId,
            },
        }).then((res) => {
            const options = res?.data || [];
            if (options) {
                setChuTeOptionDatasource(options);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    const getChuteInfo = useCallback((detailId) => {
        cnRequest({
            url: '/api/chute_rule/chute_info',
            method: 'GET',
            params: {
                id: detailId,
            },
        }).then((res) => {
            const data = res?.data || {};
            if (data) {
                const { name, ucsChuteId, autoOutbound, ruleList } = data;
                setValue({
                    ...data,
                    id: detailId,
                    chuteIdList: ucsChuteId,
                    outbound: autoOutbound,
                    ruleList: ruleList?.map((item) => {
                        const { ruleItemList } = item;
                        return {
                            ...item,
                            ruleItemList: ruleItemList?.map((ruleItemListCell) => {
                                const { itemId } = ruleItemListCell;
                                return {
                                    ...ruleItemListCell,
                                    id: itemId,
                                };
                            }),
                        };
                    }),
                });
                setChuTeOptionDatasource([
                    {
                        label: name,
                        value: ucsChuteId,
                    },
                ]);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    const getChuteOverView = useCallback((chuteId) => {
        if (!chuteId) {
            return;
        }
        cnRequest({
            url: '/api/chute_rule/chute_list',
            method: 'GET',
            params: {
                id: chuteId,
            },
        }).then((res) => {
            const data = res?.data || {};
            if (data) {
                const { equipment = {} } = data;
                const { projectId: equipmentProjectId = '' } = equipment;
                setProjectId(equipmentProjectId);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    useEffect(() => {
        getMactDatasource();
        getPriorityDatasource();
        getChuteOverView(urlId);
    }, [getMactDatasource, getPriorityDatasource, getChuteOverView, urlId]);
    useEffect(() => {
        if (isAddMode || !id) {
            setValue({
                ...defaultValue,
            });
            getChuTeOption(urlId);
            return;
        }
        getChuteInfo(id);
    }, [id, isAddMode, getChuteInfo, getChuTeOption, urlId]);
    const handleSubmit = useCallback((v) => {
        const { outbound: autoOutbound, chuteIdList, ruleList } = v;
        if (!urlId) {
            return CnMessage.error($i18n.get({ id: 'UrlParameterIdMissing', dm: 'url参数id缺失' }));
        }
        const params = {
            autoOutbound,
            projectId,
            chuteIdList,
            schemaId: parseInt(urlId, 10),
            maxTagCount: 1,
            ruleQOList: ruleList?.map((item) => {
                const { priority, ruleItemList = [] } = item;
                const result = {
                    ...item,
                    priority,
                    itemQOList: ruleItemList?.map((ruleItem) => {
                        const { matchField, matchValueList = [] } = ruleItem;
                        const selectedMatchField = matchDatasource?.find((one) => one.value === matchField);
                        const type = selectedMatchField?.valueInput;
                        if (type === 'address') {
                            const tmpItemData = {
                                ...ruleItem,
                                matchAddrList: matchValueList?.map((matchValue) => {
                                    if (typeof matchValue === 'string') {
                                        return {
                                            level: matchField,
                                            id: matchValue,
                                        };
                                    }
                                    const { districtCode = '', cityCode = '', provinceCode = '', } = matchValue;
                                    let level = 'provinceId';
                                    let tmpId = provinceCode;
                                    if (cityCode) {
                                        level = 'cityId';
                                        tmpId = cityCode;
                                    }
                                    if (districtCode) {
                                        level = 'area';
                                        tmpId = districtCode;
                                    }
                                    return {
                                        level,
                                        id: tmpId,
                                    };
                                }),
                            };
                            return omit(tmpItemData, 'matchValueList');
                        }
                        return ruleItem;
                    }),
                };
                delete result.ruleItemList;
                return result;
            }),
        };
        if (id && !isAddMode) {
            params.id = id;
        }
        cnRequest({
            url: '/api/chute_rule/update_chute',
            method: 'POST',
            data: {
                csrfId: null,
                data: params,
            },
        }).then((res) => {
            const data = res?.data || {};
            if (data) {
                CnMessage.success($i18n.get({ id: 'SavedSuccessfully', dm: '保存成功' }));
                _dialogRef?.close?.();
                _context.$('node_ocl4dw4gow2')?.remoteInstance?.load?.();
                _context.$('node_ocl6hke5ut6')?.remoteInstance?.load?.();
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, [projectId, urlId, _dialogRef, id, _context, isAddMode, matchDatasource]);
    return (React.createElement("div", { "x-class": [className, 'sorter-rule-wrapper'] },
        React.createElement(Form, { value: value, 
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange: (v) => {
                // setValue(v);
            }, field: field, useLabelForErrorMessage: true },
            React.createElement(Form.Item, { name: "chuteIdList", label: $i18n.get({ id: 'GridName', dm: '格口名称' }), required: true, className: "sorter-rule-ucs-chute" },
                React.createElement(CnAsyncSelect, { value: value?.chuteIdList, onChange: (v) => {
                        setValue({
                            ...value,
                            chuteIdList: v,
                        });
                    }, hasClear: true, dataSource: chuTeOptionDatasource, disabled: !isAddMode })),
            React.createElement(Form.Item, { name: "outbound" },
                React.createElement(CnCheckbox, { checked: value?.outbound, onChange: (v) => {
                        setValue({
                            ...value,
                            outbound: v,
                        });
                    } }, $i18n.get({ id: 'WhetherToExitTheWarehouse', dm: '是否出库' }))),
            React.createElement(DynamicInputs, { value: value, setValue: setValue, matchDatasource: matchDatasource, priorityDatasource: priorityDatasource }),
            React.createElement(Form.Item, { label: " ", className: "sorter-rule-submit" },
                React.createElement(Form.Submit, { style: { width: '100%' }, type: "primary", validate: true, onClick: () => {
                        field.validate((error, v) => {
                            if (!error) {
                                handleSubmit(v);
                            }
                        });
                    } }, $i18n.get({ id: 'Save', dm: '保存' }))))));
}
// 此行导致联调预览页面组件不展示
// export const SorterRuleWithI18n = withI18n(SorterRule, { locale });
// (SorterRuleWithI18n as any).displayName = componentName;
SorterRule.displayName = componentName;
export { SorterRule };
