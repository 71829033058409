import $i18n from '@alife/panda-i18n'; /* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import ImageViewer from '@ali/lst-image-viewer';
import { componentName } from './const';
import { CustomSlider } from './components';

import './index.scss';

const goodsInfoDefault = {
  images: [
    {
      url: 'https://img.alicdn.com/tps/TB1bewbNVXXXXc5XXXXXXXXXXXX-1000-300.png',
      text: $i18n.get({ id: 'LittleBabyBigSadaYes', dm: '小娃大萨达撒是的' }),
    },
    {
      url: 'https://img.alicdn.com/tps/TB1xuUcNVXXXXcRXXXXXXXXXXXX-1000-300.jpg',
      text: $i18n.get({ id: 'GooseGooseHungry', dm: '测鹅鹅鹅饿' }),
    },
    {
      url: 'https://img.alicdn.com/tps/TB1ikP.NVXXXXaYXpXXXXXXXXXX-1000-300.jpg',
      text: $i18n.get({ id: 'OutsidePuppetOhQuotaIs', dm: '外偶偶哦额度为' }),
    },
    {
      url: 'https://img.alicdn.com/tps/TB1s1_JNVXXXXbhaXXXXXXXXXXX-1000-300.jpg',
      text: $i18n.get({ id: 'Received', dm: '收到成为' }),
    },
  ],

  goodsDesc: [
    {
      label: $i18n.get({ id: 'CommodityDescription', dm: '商品描述：' }),
      value: '得的饿啊大卡司大萨达',
    },
    {
      label: $i18n.get({ id: 'DefaultBarcode', dm: '默认条码：' }),
      value: '0987654345678908，678908765456789876，567890987656789',
    },
  ],

  reminder: $i18n.get({ id: 'NoPhysicalBarCode', dm: '实物无条码!' }),
};

const ImageInfoViewer = (props) => {
  const { dataInfo } = props || {};
  const { dataOrigin = 'static', dataSource, requestConfig } = dataInfo || {};
  const [activeImg, setActiveImg] = useState({});
  const [bShowViewer, setShowViewer] = useState(false);
  const [curImgViewer, setCurImgViewer] = useState([]);
  const [goodsInfo, setGoodsInfo] = useState(goodsInfoDefault);

  const fetchData = async () => {
    const { data } = await window.cnLowCodeUtils?.request?.makeRequest?.({
      // 请求成功后是否toast提示
      needSuccessToast: false,
      buttonConfig: {
        options: {
          requestConfig,
        },
      },
      recordDataSource: {},
      // eslint-disable-next-line react/prop-types
      state: props?._context?.state || {},
    });
    const [imagesDefault] = data?.images || [];
    setGoodsInfo(data || {});
    setActiveImg(imagesDefault || {});
  };

  useEffect(() => {
    if (dataOrigin === 'static') {
      const [imagesDefault] = dataSource?.goodsInfo?.images || [];
      setGoodsInfo(dataSource?.goodsInfo || {});
      setActiveImg(imagesDefault || {});
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrigin, dataSource, requestConfig]);

  const handleCloseViewer = () => {
    setShowViewer(false);
  };

  return (
    <>
      <div className="image_viewer_layout">
        <div
          className="left_part"
          style={{
            width: goodsInfo.goodsDesc || goodsInfo.reminder ? '75%' : '100%',
          }}
        >
          {activeImg ? <div className="image_title">{activeImg?.text}</div> : null}
          <CustomSlider
            imagesList={goodsInfo?.images || []}
            setActiveImg={setActiveImg}
            setCurImgViewer={setCurImgViewer}
            setShowViewer={setShowViewer}
          />
        </div>
        {goodsInfo.goodsDesc || goodsInfo.reminder ? (
          <div className="right_part">
            {(goodsInfo?.goodsDesc || []).map((item, index) => {
              return (
                <div className="item_desc" key={index}>
                  <div className="desc_label">{item.label}</div>
                  <div className="desc_value">{item.value}</div>
                </div>
              );
            })}

            {goodsInfo.reminder ? <div className="reminder_text">{goodsInfo?.reminder}</div> : null}
          </div>
        ) : null}
      </div>
      <ImageViewer
        x-if={bShowViewer}
        visible
        images={curImgViewer}
        onClose={handleCloseViewer}
        zIndex={10000}
      />
    </>
  );
};

ImageInfoViewer.displayName = componentName;

export { ImageInfoViewer };
