import $i18n from '@alife/panda-i18n';
import React, { useCallback } from 'react';
import { CnMessage, CnButton } from '@alife/cn-ui';
import { componentName } from './const';
import cnRequest from 'src/api';
import { getUUID } from '@alife/cn-sc-print-base/es/base/utils';
import CnCommonPrint from '@alife/cn-sc-print-base/es/base';
import './index.scss';
function formatData(data) {
    const arr = [];
    const configs = {};
    let index = 1;
    const { documents } = data;
    let taskID = data.taksID || data.taskId;
    if (!taskID) {
        taskID = new Date().getTime() + getUUID();
    }
    documents.forEach((item) => {
        if (!configs[item.configName]) {
            configs[item.configName] = index;
            index++;
            arr[index - 1] = {
                configName: item.configName,
                documents: [],
                taskID: `${taskID}${index}`,
            };
        }
        arr[index - 1].documents.push(item);
    });
    return arr;
}
/**
 * 定制业务组件，无复用能力
 * 供 b2b拣选单查询 使用
 */
const B2bAddBox = ({ _context = {}, _dialogRef = {}, }) => {
    const currentRow = _context?.state?.CnTable?.__tableCurrentRow__ || {};
    const { pickBillId } = currentRow;
    const printWS2 = new CnCommonPrint({
        isNeedWetPrint: true,
        isNeedCloudPrint: false,
        isNeedPddPrint: true,
        isNeedJdPrint: true,
        isNeedBytePrint: true,
        isNeedKuaiShouPrint: true,
        isNeedVipPrint: true, // 是否启用唯品会打印
    });
    const printPub = (data, tps) => {
        const printArr = formatData(data);
        printArr.forEach((item) => {
            // 调用打印接口
            const printData = {
                data: {
                    requestID: getUUID(),
                    version: '1.0',
                    cmd: 'print',
                    task: {
                        taskID: item.taskID,
                        documents: item.documents,
                    },
                    // warehouseId: data.warehouseId,
                    responseTime: data.responseTime || '',
                    networkTime: tps,
                    // bizIdentity: data.bizIdentity || '',
                    taskID: data.taskId || '',
                    traceId: data.traceId || '',
                },
                configName: item.configName,
                businessType: 1,
                messageType: 3,
            };
            printWS2?.printData?.(printData);
        });
        _context.$('node_ocl4dw4gow2')?.remoteInstance?.load?.();
        _dialogRef?.close?.();
    };
    const handleOk = useCallback(() => {
        cnRequest({
            url: '/api/PC2BPickResource/generateVirtualContainers',
            method: 'post',
            data: {
                pickBillId,
                num: 1,
                type: 10,
            },
        }).then((response) => {
            const list = (response && response.result) || [];
            const printList = list.join(',');
            let tps = new Date().getTime();
            cnRequest({
                url: '/api/PC2BPickResource/printBoxCode',
                method: 'POST',
                data: {
                    billType: 'boxmark_bill',
                    personalParams: {},
                    pickBillIds: pickBillId,
                    printType: 'repeat',
                    taskId: getUUID(),
                    warehouseId: '',
                    context: {
                        vmContainerCodes: printList,
                    },
                },
            })
                .then((res) => {
                tps = new Date().getTime() - tps;
                if (res?.data?.documents) {
                    printPub(res?.data, tps);
                }
            })
                .catch((res) => {
                CnMessage.error((res.error && res.error.message) || $i18n.get({ id: 'NetworkError', dm: '网络错误' }));
            });
        });
    }, []);
    return (React.createElement("div", { className: "b2b-add-box" },
        $i18n.get({ id: 'DoYouWantToAddBoxes_1951837552', dm: '是否加箱？' }),
        React.createElement("div", { className: "b2b-add-box-footer cn-dialog-alc-footer" },
            React.createElement(CnButton, { type: "secondary", onClick: () => {
                    _dialogRef?.close?.();
                } }, $i18n.get({ id: 'Cancel', dm: '取消' })),
            React.createElement(CnButton, { type: "primary", onClick: () => handleOk() }, $i18n.get({ id: 'Determine', dm: '确定' })))));
};
B2bAddBox.displayName = componentName;
export { B2bAddBox };
