import React, { useState, useEffect } from 'react';
import Viewerjs from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import './index.scss';

export default (props) => {
  const { image = '', maxWidth = 100, maxHeight = 100, containerWidth = null } = props;
  const [viewerInstacne, setViewerInstacne] = useState(null);

  useEffect(() => {}, []);

  return (
    <div className="image-preview">
      <span className="img-preview-scale-container" style={{ width: containerWidth }}>
        <img
          ref={(ref) => {
            if (!viewerInstacne && ref) {
              setViewerInstacne(new Viewerjs(ref, { viewed: () => {} }));
            }
          }}
          src={image}
          alt=""
          className="img-preview-scale"
          style={{ maxWidth, maxHeight }}
        />
      </span>
    </div>
  );
};
