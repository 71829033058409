import $i18n from '@alife/panda-i18n';
import React, { useCallback, useState } from 'react';
import { Grid, Field, CnDialog, CnMessage, Form, CnAsyncSelect, CnInputTextArea, } from '@alife/cn-ui';
import { MOVE_EXT_TYPE, MOVE_LOC_TYPE_DATASOURCE } from '../../const';
import { OwnerSelect } from 'src/components/owner-select';
import cnRequest from 'src/api';
const { Col, Row } = Grid;
export default function Step1(props) {
    const { isAddMode, setNewId, visible, setVisible } = props;
    const [value, setValue] = useState({
        type: 1,
        extType: MOVE_EXT_TYPE[0].value,
    });
    const field = Field.useField({ values: value });
    const handleOk = useCallback(() => {
        field.validate((errs, values) => {
            if (!errs) {
                cnRequest({
                    url: '/api/move/add',
                    method: 'POST',
                    data: values,
                }).then((res) => {
                    const { data: resData, success, error, } = res;
                    if (success) {
                        const newId = resData?.id;
                        setNewId(newId);
                        setValue({ type: 1 });
                        field.setValues({ type: 1 });
                    }
                    else {
                        CnMessage.error(error?.message ||
                            error?.code ||
                            $i18n.get({ id: 'UnknownException', dm: '未知异常' }));
                    }
                });
            }
        });
    }, [field, setNewId]);
    const handleClose = useCallback(() => {
        setVisible(false);
        setNewId(null);
        setValue({ type: 1 });
        field.setValues({ type: 1 });
    }, []);
    return visible ? (React.createElement(CnDialog, { title: isAddMode
            ? $i18n.get({ id: 'NewShiftOrder', dm: '新建移位单' })
            : $i18n.get({ id: 'EditShiftList', dm: '编辑移位单' }), visible: visible, size: "medium", className: "biz-move-location-dialog-step2", popupContainer: document.querySelector('body'), onClose: () => handleClose(), onCancel: () => handleClose(), onOk: () => {
            handleOk();
        } },
        React.createElement(Form, { field: field, className: "biz-move-location-dialog-step1", value: value, onChange: (v) => {
                setValue(v);
            }, useLabelForErrorMessage: true },
            React.createElement(Row, { wrap: true, gutter: 8 },
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: $i18n.get({ id: 'ShiftType.vegxy', dm: '移位类型' }), required: true },
                        React.createElement(CnAsyncSelect, { style: { width: '100%' }, dataSource: MOVE_LOC_TYPE_DATASOURCE, showSearch: true, hasClear: true, name: "type" }))),
                value?.type === 2 && (React.createElement(React.Fragment, null,
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: $i18n.get({ id: 'SourceType', dm: '来源类型' }), required: true },
                            React.createElement(CnAsyncSelect, { style: { width: '100%' }, dataSource: MOVE_EXT_TYPE, showSearch: true, hasClear: true, mode: "single", name: "extType" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: $i18n.get({
                                id: '31255241940930560.CNTM',
                                dm: '货主',
                            }), required: true },
                            React.createElement(OwnerSelect, { style: { width: '100%', paddingRight: 4 }, mode: "single", name: "ownerId" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: $i18n.get({
                                id: 'SourceReservoirArea.OLGYA',
                                dm: '来源库区',
                            }), required: true },
                            React.createElement(CnAsyncSelect, { style: { width: '100%' }, remoteUrl: "/api/move/section/list?type=1&mvType=2", showSearch: true, hasClear: true, mode: "single", name: "fromWarehouseAreaId" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: $i18n.get({
                                id: 'TargetReservoirArea.OtcqW',
                                dm: '目标库区',
                            }), required: true },
                            React.createElement(CnAsyncSelect, { style: { width: '100%' }, remoteUrl: "/api/move/section/list?type=2&mvType=2", showSearch: true, hasClear: true, mode: "single", name: "toWarehouseAreaId" }))))),
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: $i18n.get({ id: 'Remarks', dm: '备注:' }) },
                        React.createElement(CnInputTextArea, { name: "remark", placeholder: $i18n.get({
                                id: 'gxms-pc_Enter.CNTM',
                                dm: '请输入',
                            }) }))))))) : null;
}
