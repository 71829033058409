import React from 'react';
import { CnAsyncSelect, formilyReact } from '@alife/cn-ui';
import { OwnerSelect } from '../owner-select';
const { useField } = formilyReact;
const BizSelectOwner = // React.memo(
 (props) => {
    const { tableCode, keyString, ownerString, selectPropsFunc, ...rest } = props;
    const field = useField?.();
    const index = field?.path?.segments?.[1];
    const type = field.query(`${tableCode}.${index}.${keyString}`).value(); // 查询其他字段的值
    const selectProps = typeof selectPropsFunc === 'function'
        ? selectPropsFunc(field.form.values, field.query(`${tableCode}`).value()?.[index])
        : {};
    return type === ownerString ? (React.createElement(OwnerSelect, { params: {
            lastActiveOptions: JSON.stringify(typeof field?.value === 'string' ? `[${field?.value}]` : field?.value || []),
        }, mode: 'multiple', ...rest })) : (React.createElement(CnAsyncSelect, { ...selectProps, ...rest }));
};
//   (props, nextProps) => {
//     console.log(`props:`, props);
//     console.log(`nextProps:`, nextProps);
//     // 此处的逻辑可以根据你的业务自己code
//     return JSON.stringify(props) === JSON.stringify(nextProps);
//   },
// );
BizSelectOwner.displayName = 'BizSelectOwner';
export { BizSelectOwner };
