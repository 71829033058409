import $i18n from '@alife/panda-i18n';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { CnButton, CnDialog, CnInput, CnMessage, CnTooltip, CnIcon, CnForm, CnFormItem, CnFormField, formilyCore, CnCheckbox, } from '@alife/cn-ui';
import { domain as domainEnv, getDefaultFileName, getExportOfflineFilter } from './utils';
import { componentName } from './const';
import { Sortable } from '../sortable';
import { getExportColumns, getUserSet, addUserSet, updateUserSet } from './api';
import './index.scss';
const { createForm } = formilyCore;
/**
 * 返回导出任务中心地址
 */
const getTaskUrl = () => {
    if (location?.host?.indexOf('-stg') > -1) {
        return 'https://pre-wmsdatahub.sg.cainiao.com/main.html#/page/zreport/exportlist';
    }
    return 'https://datahub.sg.cainiao.com/main.html#/page/zreport/exportlist';
};
function LZDExportNew(props) {
    const { name: propsName, selectedRowRecords = [], selectedRowKeys, _bindFilterData, params, className, popupClassName, children, footerActions = ['cancel', 'ok'], taskCenterUrl: taskUrl = getTaskUrl(), preReleaseUrl, releaseUrl, customExportFn, progressData, preCheck, csrfToken, isNeedCsrf, isJump2History, primaryKey = '', requestOptions, csrfUrl, tip, isMenuItem = false, paramsJsx, _state, size = 'small', popupContainer, 
    // 导出v2低码-专用配置项兼容
    v2ConfigJsx, } = props;
    // 增加 exportMode\version\domain, 表示：主单/明细、新版本、页面用于名称
    let { 
    // 导出v2源码-版本
    version, 
    // 导出v2源码-开启列设置
    exportCloumnSet = false, 
    // 导出v2源码-按钮唯一区分key
    domain, 
    // 导出v2源码-导出明细主单/明细
    exportMode, 
    // 导出v2源码-导出默认文件名称
    domain_cn, 
    // 导出url
    exportUrl, 
    // 包裹data
    isNeedDataWrapper = true, } = props;
    // 兼容低码配置项
    if (v2ConfigJsx) {
        try {
            const { pageCode, isShowCloumnSet, defaultExportFileName, isExportDetail, url, isNeedDataWrapper: v2IsNeedDataWrapper, } = v2ConfigJsx?.() || {};
            if (Object.keys(v2ConfigJsx?.() || {}).length > 0) {
                // 认为配置了低码兼容配置项，就是V2版本
                version = 'V2';
            }
            if (isShowCloumnSet === true) {
                exportCloumnSet = isShowCloumnSet;
            }
            if (pageCode) {
                domain = pageCode;
            }
            if (url) {
                exportUrl = url;
            }
            if (isExportDetail) {
                exportMode = 2;
            }
            else {
                exportMode = 1;
            }
            if (typeof v2IsNeedDataWrapper !== 'undefined') {
                isNeedDataWrapper = v2IsNeedDataWrapper;
            }
            if (defaultExportFileName) {
                domain_cn = defaultExportFileName;
            }
            // 导出v2源码-导出默认文件名称
        }
        catch (e) {
            console.error('低码解析V2导出失败', e);
        }
    }
    const name = children || propsName || $i18n.get({ id: 'Export', dm: '导出' });
    const container = popupContainer ? { popupContainer } : {};
    const [visible, setVisible] = useState(false);
    const [dsize, setDsize] = useState(size);
    const [loading, setLoading] = useState(false);
    const [exportColumns, setExportColumns] = useState([]);
    const [showSetDiv, setShowSetDiv] = useState(false);
    const [selectNum, setSelectNum] = useState(0);
    const [userTarsId, setUserTarsId] = useState(0);
    const csrfId = useRef(null);
    const form = useMemo(() => createForm({
        initialValues: {},
    }), []);
    exportUrl = location?.host?.indexOf('-stg') > -1 ? preReleaseUrl : releaseUrl;
    let taskCenterUrl = taskUrl;
    if (version === 'V2') {
        console.log('version', version);
        taskCenterUrl = getTaskUrl();
    }
    const selectStatus = useMemo(() => {
        if (selectNum === exportColumns?.length) {
            return 'all';
        }
        if (selectNum && selectNum < exportColumns?.length) {
            return 'some';
        }
        return null;
    }, [selectNum, exportColumns]);
    useEffect(() => {
        if (visible) {
            const taskName = getDefaultFileName({
                exportMode,
                domain,
                domain_cn,
            });
            name &&
                form.setValues({
                    taskName,
                });
            if (exportCloumnSet === true) {
                setDsize('medium');
                // setShowExportSetting(true);
                Promise.all([
                    getExportColumns(domain, exportMode),
                    getUserSet(domain, exportMode === 2),
                ]).then(([columns, users]) => {
                    console.log('getExportColumns：server+users', columns, users);
                    if (columns && columns.length && users && users.data && users.data.length) {
                        setExportColumns([
                            // 用户设置的顺序
                            ...users.data
                                .map((e) => columns.find((o) => o.key === e))
                                .filter((e) => e),
                            // 剩余的字段
                            ...columns
                                .filter((e) => !users.data.includes(e.key))
                                .map((o) => ({ ...o, visible: false })),
                        ]);
                        setUserTarsId(users.index);
                    }
                    else if (columns && columns.length) {
                        setExportColumns(columns);
                    }
                    else if (users && users.data && users.data.length) {
                        setExportColumns(users.data.map((e) => ({
                            key: e,
                            visible: true,
                            name: e,
                        })));
                        setUserTarsId(users.index);
                    }
                });
            }
        }
        else {
            setExportColumns([]);
            // setDsize(size === 'small' ? 'medium' : size);
            setShowSetDiv(false);
        }
    }, [visible]);
    useEffect(() => {
        const checkeds = exportColumns.filter((e) => e.visible);
        setSelectNum(checkeds.length);
    }, [exportColumns]);
    const getCsrf = () => {
        if (csrfId.current) {
            return Promise.resolve();
        }
        if (csrfToken) {
            csrfId.current = csrfToken;
            return Promise.resolve();
        }
        let tmpCsrfUrl = csrfUrl || `${domainEnv.getDomain('data')}/api/csrf`;
        if (exportUrl) {
            if (exportUrl.indexOf('//') === 0) {
                try {
                    const tmpUrl = new URL(`https:${exportUrl}`);
                    tmpCsrfUrl = `${tmpUrl.origin}/api/csrf`;
                }
                catch (e) {
                    //
                }
            }
            else if (exportUrl.indexOf('http') === 0) {
                try {
                    const tmpUrl = new URL(exportUrl);
                    tmpCsrfUrl = `${tmpUrl.origin}/api/csrf`;
                }
                catch (e) {
                    //
                }
            }
            else if (exportUrl.indexOf('/') === 0) {
                tmpCsrfUrl = '/api/csrf';
            }
        }
        return fetch(tmpCsrfUrl, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((data) => data.json())
            .then((res) => {
            if (res?.success) {
                if (res?.data?.token) {
                    csrfId.current = res?.data?.token;
                    return Promise.resolve();
                }
                return Promise.reject();
            }
            CnMessage.show({
                type: 'error',
                content: res?.error?.message,
            });
            return Promise.reject();
        })
            .catch((error) => {
            let errorMessage = '';
            if (error.response) {
                errorMessage = $i18n.get({
                    id: 'SystemExceptionErrorResponseStatuserrorresponsestatitext',
                    dm: '系统异常 {errorResponseStatus} - {errorResponseStatusText}',
                }, {
                    errorResponseStatus: error.response.status,
                    errorResponseStatusText: error.response.statusText,
                });
            }
            else {
                errorMessage = error.message || $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' });
            }
            CnMessage.error(errorMessage);
            return Promise.reject();
        });
    };
    const getSelectedKeys = (recordKey, records) => {
        const selectedKeys = [];
        records.forEach((n) => {
            selectedKeys.push(n[recordKey]);
        });
        return selectedKeys;
    };
    const onOk = () => {
        const queryParams = {};
        let param = {};
        if (!form.valid) {
            return null;
        }
        form.validate().then(() => {
            setLoading(true);
            const { taskName } = form.values;
            if (customExportFn) {
                customExportFn(taskName, taskCenterUrl)
                    .then(() => {
                    setLoading(false);
                    setVisible(false);
                    // dialogInstance && dialogInstance.hide();
                })
                    .catch(() => setLoading(false));
                return;
            }
            if (Array.isArray(selectedRowKeys) && selectedRowKeys.length) {
                selectedRowKeys.forEach(() => {
                    queryParams[primaryKey] = getSelectedKeys(primaryKey, selectedRowRecords);
                });
            }
            Object.assign(queryParams, _bindFilterData);
            if (paramsJsx && typeof paramsJsx === 'function') {
                Object.assign(param, paramsJsx(_state));
            }
            else if (params && typeof params === 'object') {
                Object.assign(param, params);
            }
            param.exportName = taskName;
            if (progressData) {
                param = progressData(param, queryParams, paramsJsx ? paramsJsx?.(_state) : params, selectedRowRecords);
            }
            else {
                // 兼容老大宝逻辑
                param.type = params?.type === 'offline' ? 2 : 1;
                param.queryParams = queryParams;
            }
            Object.keys(param?.queryParams || {}).forEach((key) => {
                if (param?.queryParams && param?.queryParams?.[key] === '') {
                    delete param.queryParams[key];
                }
                if (param?.queryParams && typeof param?.queryParams?.[key] === 'undefined') {
                    delete param.queryParams[key];
                }
                if (param?.queryParams &&
                    Array.isArray(param?.queryParams?.[key]) &&
                    !param?.queryParams?.[key]?.length) {
                    delete param.queryParams[key];
                }
            });
            // 新版离线导出
            if (version === 'V2') {
                param.exportMode = exportMode;
                param.domain = domain;
            }
            if (exportCloumnSet) {
                param = getExportOfflineFilter(param, props);
                // 新增导出字段
                param.frontExportFieldList = (exportColumns || [])
                    .filter((e) => e.visible)
                    .map((e) => e.key);
            }
            const doExport = () => {
                fetch(exportUrl, {
                    method: 'post',
                    body: isNeedDataWrapper
                        ? JSON.stringify({
                            data: param,
                            csrfId: csrfId.current,
                            _csrf: csrfId.current,
                        })
                        : JSON.stringify(param),
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        'content-Type': 'application/json',
                    },
                    ...requestOptions,
                })
                    .then((res) => res.json())
                    .then((res) => {
                    if (res?.success) {
                        setVisible(false);
                        CnMessage.show({
                            type: 'success',
                            duration: 5000,
                            content: (React.createElement("span", { className: "task-center-notice" },
                                $i18n.get({
                                    id: 'TheTaskWasCreatedSuccessfullyClick_344111788',
                                    dm: '任务创建成功，点击到',
                                }),
                                React.createElement(CnButton, { text: true, component: "a", href: taskCenterUrl, target: "_blank", type: "primary", "data-autolog": $i18n.get({
                                        id: 'CustomPathExportactionTextTaskCe_1676495441',
                                        dm: 'customPath=export.action&text=任务中心',
                                    }) }, $i18n.get({ id: 'MissionCenter', dm: '任务中心' })),
                                $i18n.get({ id: 'View', dm: '查看' }))),
                        });
                    }
                    else {
                        CnMessage.show({
                            type: 'error',
                            content: res?.error?.message || $i18n.get({ id: 'ExportFailed', dm: '导出失败' }),
                        });
                    }
                    setLoading(false);
                })
                    .catch((err) => {
                    let errorMessage = '';
                    if (err?.response) {
                        errorMessage = $i18n.get({
                            id: 'SystemExceptionErrResponseStatuserrresponsestatitext',
                            dm: '系统异常 {errResponseStatus} - {errResponseStatusText}',
                        }, {
                            errResponseStatus: err.response.status,
                            errResponseStatusText: err.response.statusText,
                        });
                    }
                    else {
                        errorMessage = err.message || $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' });
                    }
                    CnMessage.show({
                        type: 'error',
                        content: errorMessage,
                    });
                })
                    .finally(() => {
                    setLoading(false);
                });
                if (exportCloumnSet) {
                    setTimeout(() => {
                        if (userTarsId) {
                            updateUserSet(domain, param.frontExportFieldList, exportMode === 2, userTarsId);
                        }
                        else {
                            addUserSet(domain, param.frontExportFieldList, exportMode === 2).then((tarsId) => {
                                setUserTarsId(tarsId);
                            });
                        }
                    }, 0);
                }
            };
            if (!isNeedCsrf) {
                doExport();
                return;
            }
            getCsrf()
                .then(() => doExport())
                .catch(() => setLoading(false));
        });
        return true;
    };
    const renderButtons = () => {
        if (isJump2History) {
            return (React.createElement("a", { href: taskCenterUrl, className: "btn-task-center", target: "_blank", rel: "noreferrer", "data-autolog": $i18n.get({
                    id: 'CustomPathExportactionTextTaskCe_1053215240',
                    dm: 'customPath=export.action&text=任务中心&clk=true',
                }) }, $i18n.get({ id: 'MissionCenter', dm: '任务中心' })));
        }
        let content;
        if (!isMenuItem) {
            content = (React.createElement(CnButton, { onClick: () => handleExport(), size: dsize, "data-hottag-name": "cn-sc-wms-biz-download", "data-autolog": `customPath=export.action&text=${name}&clk=true` }, name));
        }
        else {
            content = (React.createElement("div", { onClick: () => handleExport(), "data-hottag-name": "cn-sc-wms-biz-download", "data-autolog": `customPath=export.action&text=${name}&clk=true` }, name));
        }
        return tip ? (React.createElement(CnTooltip, { trigger: content, triggerType: "hover", align: "r", v2: true, followTrigger: true }, tip)) : (content);
    };
    const handleExport = () => {
        if (preCheck && !preCheck()) {
            return;
        }
        // const defaultName = getDefaultFileName(props);
        setVisible(true);
    };
    return (React.createElement("div", { style: { flexGrow: 1 } },
        React.createElement("div", { className: classnames('cn-sc-export', className) }, renderButtons()),
        React.createElement(CnDialog, { className: classnames('cn-sc-export-pop', popupClassName), visible: visible, onClose: () => setVisible(false), 
            // style={{ width: 400 }}
            size: dsize, title: $i18n.get({ id: 'ExportFileNaming', dm: '导出文件命名' }), ...container, "data-autolog": $i18n.get({
                id: 'CustomPathExportdialogTextExport_879909981',
                dm: 'customPath=export.dialog&text=导出命名&clk=true',
            }), footerActions: footerActions, onCancel: () => setVisible(false), onOk: onOk, okProps: {
                loading,
            }, ...(showSetDiv
                ? {
                    footer: (React.createElement("div", { className: "cn-sc-export-pop-selectall" },
                        React.createElement(CnCheckbox, { indeterminate: selectStatus === 'some', checked: selectStatus === 'all', onChange: () => setExportColumns((prev) => {
                                return prev?.map?.((item) => {
                                    return {
                                        ...item,
                                        visible: selectStatus !== 'all',
                                    };
                                });
                            }) }, $i18n.get({ id: 'SelectAll', dm: '全选' })))),
                }
                : {}) },
            React.createElement(CnForm, { form: form, ...{
                    style: { marginBottom: '-15px' },
                }, hasFooterSubmit: false },
                React.createElement(CnFormField, { name: "taskName", required: true },
                    React.createElement(CnInput, { name: "taskName", placeholder: $i18n.get({
                            id: 'gxms-pc_Enter.CNTM',
                            dm: '请输入',
                        }), hasClear: true, style: { width: '100%' } })),
                exportCloumnSet && (React.createElement(CnFormItem, null,
                    React.createElement(CnMessage, { type: "warning", className: "export-setting" },
                        React.createElement("span", null,
                            $i18n.get({
                                id: 'NumberOfExportedFieldsNumberOfEx_1235377980',
                                dm: '本次导出字段数/可导出字段数为：',
                            }),
                            selectNum,
                            "/",
                            exportColumns.length),
                        React.createElement(CnButton, { size: "small", type: "normal", onClick: () => {
                                if (!showSetDiv) {
                                    setDsize('large');
                                }
                                else {
                                    setDsize('medium');
                                }
                                setShowSetDiv(!showSetDiv);
                            }, style: { position: 'absolute', right: '15px', top: '4px' }, "data-autolog": $i18n.get({
                                id: 'CustomPathExportsetTextDynamicCo_505789810',
                                dm: 'customPath=export.set&text=动态列设置&clk=true',
                            }) },
                            showSetDiv
                                ? $i18n.get({
                                    id: 'DynamicColumnCollapse',
                                    dm: '动态列收起',
                                })
                                : $i18n.get({
                                    id: 'DynamicColumnSettings',
                                    dm: '动态列设置',
                                }),
                            React.createElement(CnIcon, { type: "setting" }))))),
                showSetDiv && (React.createElement(CnFormItem, { style: { maxHeight: '380px', overflowY: 'auto' } },
                    React.createElement(Sortable, { adjustScale: true, 
                        // onChange={sortableOnChange}
                        // Container={(p) => <GridContainer {...p} />}
                        handle: true, wrapperStyle: (p) => ({
                            ...p,
                            width: 155.5,
                            height: 35,
                        }), items: exportColumns, setItems: setExportColumns })))))));
}
LZDExportNew.displayName = componentName;
export { LZDExportNew };
