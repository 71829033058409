import $i18n from '@alife/panda-i18n';
/**
 * 条码组件
 * 中间空格，后面粗体，支持不带空格
 * 如：SL203004 0989
 */
import React, { useRef } from 'react';
import classnames from 'classnames';
import Clipboard from 'clipboard';
import { CnMessage } from '@alife/cn-ui';
import './index.scss';
const Code = ({ value, lenLimit = 25, hightlightNum: highlightNum = 4, isSplitSpace = true, href, }) => {
    const timerRef = useRef(null);
    if (typeof value === 'undefined') {
        return null;
    }
    const str = `${value}`;
    let prefix = str.substr(0, str.length - highlightNum);
    if (prefix.length > lenLimit - highlightNum) {
        prefix = `...${prefix.substr(prefix.length - (lenLimit - highlightNum))}`;
    }
    const orderCode1 = prefix;
    const orderCode2 = str.substr(-highlightNum);
    return (React.createElement("div", { className: classnames('split-code-box', {
            'with-space': isSplitSpace,
            'no-sapce': !isSplitSpace,
            'with-href': !!href,
        }), onDoubleClick: (e) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            e?.stopPropagation?.();
            const clipboard = new Clipboard('.split-code-box');
            clipboard.on('success', () => {
                CnMessage.success($i18n.get({ id: 'ReplicationSuccessful', dm: '复制成功' }));
            });
            clipboard.on('erroe', () => {
                CnMessage.error($i18n.get({ id: 'ReplicationFailed', dm: '复制失败' }));
            });
            // clipboard.onClick(e);
            clipboard.destroy();
        }, onClick: (e) => {
            if (!href) {
                return;
            }
            e?.stopPropagation?.();
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
                if (timerRef.current) {
                    window.open(href);
                }
            }, 800);
        }, "data-clipboard-text": value },
        React.createElement("span", { className: "split-code1" }, orderCode1),
        React.createElement("span", { className: "split-code2" }, orderCode2)));
};
export default Code;
