import $i18n from '@alife/panda-i18n';
import React from 'react';
import { CnAsyncSelect, CnInput, CnFilter, CnFilterItem, CnRangeDatePickerPro } from '@alife/cn-ui';
import { OwnerSelect } from 'src/components/owner-select';
import { BatchInput } from 'src/components/batch-input';
export default function Filter({ data, setData, dataRef, handleQuery, }) {
    return (React.createElement(CnFilter, { value: data.filterData, showFolder: false, onChange: (values) => setData({
            ...(dataRef?.current || {}),
            filterData: {
                ...(dataRef?.current?.filterData || {}),
                ...values,
            },
        }), onSearch: () => handleQuery() },
        React.createElement(CnFilterItem, { label: $i18n.get({ id: '31255241940930560.CNTM', dm: '货主' }), name: "ownerId" },
            React.createElement(OwnerSelect, { mode: "single", params: {
                    lastActiveOptions: data.filterData.ownerId ? [data.filterData.ownerId] : [],
                }, disabled: data.infoData.type === 2 })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'SourceReservoirArea.OLGYA', dm: '来源库区' }), name: "warehouseAreaIdList" },
            React.createElement(CnAsyncSelect, { remoteUrl: `/api/move/section/list?type=1&mvType=2`, showSearch: true, hasClear: true, disabled: data.infoData.type === 2, mode: "multiple" })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'SourceLocation', dm: '来源库位' }), name: "cabinetCodeList" },
            React.createElement(BatchInput, { maxCount: 100 })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: '31255242679128065.CNTM', dm: '商品条码' }), name: "itemBarcodeList" },
            React.createElement(BatchInput, { maxCount: 100 })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'InventoryStatus', dm: '库存状态' }), name: "inventoryStatus" },
            React.createElement(CnAsyncSelect, { remoteUrl: "/api/move/inventoryTypeOptions", showSearch: true, hasClear: true })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: '31255237780180992.CNTM', dm: '批次编码' }), name: "itemBatchCode" },
            React.createElement(CnInput, { hasClear: true, placeholder: $i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' }) })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: '31255240057688064.CNTM', dm: '供应商' }), name: "providerCode" },
            React.createElement(CnAsyncSelect, { remoteUrl: "/api/move/providerOptions", showSearch: true, hasClear: true })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'ProductionDate', dm: '生产日期' }), name: "produceDate", span: 2 },
            React.createElement(CnRangeDatePickerPro, { resetTime: true, showTime: true, outputFormat: "YYYY-MM-DD HH:mm:ss" })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'ExpirationDate', dm: '失效日期' }), name: "expiryDate", span: 2 },
            React.createElement(CnRangeDatePickerPro, { resetTime: true, showTime: true, outputFormat: "YYYY-MM-DD HH:mm:ss" })),
        React.createElement(CnFilterItem, { label: $i18n.get({ id: 'ReservoirType', dm: '库区类型' }), name: "warehouseAreaTypeList" },
            React.createElement(CnAsyncSelect, { remoteUrl: "/api/move/warehouseAreaType/list", showSearch: true, hasClear: true, disabled: data.infoData.type === 2, mode: "multiple" })),
        (data?.itemBatchFieldList || []).map((item) => {
            return (React.createElement(CnFilterItem, { label: item.name, name: item.code }, item?.type === 'enum' ? (React.createElement(CnAsyncSelect, { dataSource: item.labelValues, showSearch: true, hasClear: true, defaultValue: item.defaultValue, readOnly: item.readOnly })) : (React.createElement(CnInput, { hasClear: true, defaultValue: item.defaultValue, readOnly: item.readOnly, placeholder: $i18n.get({
                    id: 'gxms-pc_Enter.CNTM',
                    dm: '请输入',
                }) }))));
        })));
}
