import $i18n from '@alife/panda-i18n';
import { useState, useMemo, useEffect, useCallback, useRef } from 'react';

const storageKey = `${location.origin}${location.pathname}_summary_keys`;

const editPageMockData = {
  summary: [
    {
      label: $i18n.get({
        id: 'SummaryInformationComponentEditPageExample_1610268962',
        dm: '汇总信息组件编辑页示例',
      }),

      value: '99999',
      valueType: 'number',
      key: 'demo',
    },
    {
      label: $i18n.get({ id: 'DocumentAddress', dm: '文档地址' }),
      value: 'https://alidocs.dingtalk.com/i/nodes/a9E05BDRVQRkezKGC1945qLyJ63zgkYA',
      valueFontSize: 12,
      key: 'doc',
    },
  ],

  summaryTip: $i18n.get({
    id: 'SummaryDataDisplayUpToTwoRows',
    dm: '汇总数据展示，最多显示两行',
  }),
};

const isEditPage =
  location.search.includes('debug-table-top-summary=true') || location.pathname === '/lowcode/edit';

function getStorageShowSummary() {
  const keys = localStorage.getItem(storageKey);

  if (keys) {
    try {
      return JSON.parse(keys);
    } catch (e) {
      return [];
    }
  }

  return [];
}

export function useInit(_getCurrentTableInfo, enableTagClick, associatedFilterId, content) {
  const [summary, setSummary] = useState(null);
  const [activeTagKey, setActiveTagKey] = useState('ALL');
  const activeTagKeyRef4Query = useRef(null);
  const activeTagKeyRef = useRef(null);

  const { summary: originSummary, summaryTip: tmpSummaryTip } = useMemo(
    () => (isEditPage ? editPageMockData : _getCurrentTableInfo?.()?.__tableExtra__ || {}),
    [_getCurrentTableInfo],
  );

  const handleClickTag = useCallback(
    (key) => {
      const cnFilter = content.$(`${associatedFilterId}`);

      activeTagKeyRef4Query.current = key;
      activeTagKeyRef.current = key;
      setActiveTagKey(key);

      cnFilter?.search?.();
    },
    [associatedFilterId, content],
  );

  useEffect(() => {
    let tmpSummary = [...(originSummary || [])];

    if (enableTagClick) {
      const isNeedHasAll = enableTagClick;
      const hasAll = tmpSummary?.find?.((one) => one.key === 'ALL');

      if (isNeedHasAll && !hasAll) {
        tmpSummary = [
          {
            key: 'ALL',
            label: $i18n.get({ id: 'All', dm: '全部' }),
            name: $i18n.get({ id: 'All', dm: '全部' }),
            visible: true,
            required: true,
          },
          ...(tmpSummary || []),
        ];
      }
    }

    const storageShowSummary = getStorageShowSummary();

    if (storageShowSummary?.length) {
      const storageShowSummaryKeys = storageShowSummary?.map?.((item) => item.key);
      const storageShowSummaryShowKeys = storageShowSummary
        ?.filter((item) => item.visible)
        ?.map?.((item) => item.key);
      const newSummary =
        tmpSummary?.filter?.((item) => !storageShowSummaryKeys?.includes?.(item.key)) || [];

      tmpSummary = [...storageShowSummary, ...newSummary]
        .map((item) => {
          const { label, key } = item;
          const visible = storageShowSummaryShowKeys?.includes?.(key) || false;
          const itemFromResponse = tmpSummary.find((one) => one.key === key);
          const nextValue = itemFromResponse?.value || 0;

          return {
            ...item,
            name: label,
            value: nextValue,
            visible,
          };
        })
        ?.filter?.((one) => one);
    } else {
      tmpSummary = tmpSummary.map((item) => {
        const { label } = item;

        return {
          ...item,
          name: label,
          visible: true,
        };
      });
    }

    setSummary(tmpSummary);
  }, [enableTagClick, originSummary]);

  return {
    summary,
    setSummary: (v) => {
      try {
        localStorage.setItem(storageKey, JSON.stringify(v || []));
      } catch (e) {
        // 设置失败
      }
      setSummary(v);
      const isActiveKeyVisible = v?.find?.((one) => one?.key === activeTagKey)?.visible;

      if (!isActiveKeyVisible) {
        handleClickTag('ALL');
      }
    },
    summaryTip: tmpSummaryTip,
    isL2Env: !!_getCurrentTableInfo,
    isShowSumDom: summary?.length > 0,
    activeTagKey,
    setActiveTagKey,
    handleClickTag,
    activeTagKeyRef,
    activeTagKeyRef4Query,
  };
}
