import $i18n from '@alife/panda-i18n';
import React, { useEffect } from 'react';
import { CnButton, CnMessage, CnLoading, CnDialog } from '@alife/cn-ui';
import cnRequestWms from 'src/api';
import api from '../../api';
// 已关联出库单通知
const Associated = (props) => {
    const { id, getBindedRvNotice, associatedList, loading } = props;
    // 校验
    const onVerify = async (order) => {
        try {
            const { data = { result: '' } } = (await cnRequestWms({
                url: api.getAndCheckUnBindVehicleAssignmentMessage,
                method: 'POST',
                data: {
                    vehicleAssignmentId: id,
                    outOrderCode: order,
                },
            }));
            const result = data?.result;
            if (result) {
                CnDialog.warning({
                    title: $i18n.get({ id: 'Prompt', dm: '提示' }),
                    content: result,
                    size: 'small',
                    okProps: {
                        children: 'Custom OK',
                    },
                    onOk: () => {
                        onDelete(order);
                    },
                });
            }
            else {
                onDelete(order);
            }
        }
        catch (e) {
            CnMessage.error(e?.message || e?.code || $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }));
        }
    };
    // 删除关联
    const onDelete = async (order) => {
        if (!order) {
            return;
        }
        try {
            const res = await cnRequestWms({
                url: api.unplanVehicleCancelBindRvNotice,
                method: 'POST',
                data: {
                    vehicleAssignmentId: id,
                    outOrderCode: order,
                },
            });
            if (res) {
                getBindedRvNotice();
            }
        }
        catch (e) {
            CnMessage.error(e?.message || e?.code || $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }));
        }
    };
    useEffect(() => {
        getBindedRvNotice();
    }, []);
    return (React.createElement("div", { className: "has-associated" },
        React.createElement("div", { className: "associated-title" }, $i18n.get({
            id: 'RelatedOutcomeOrderNotification',
            dm: '已关联出库单通知',
        })),
        loading ? (React.createElement("div", { className: "associated-table-loading" },
            React.createElement(CnLoading, { tip: $i18n.get({ id: 'Loading', dm: '加载中...' }) }))) : (React.createElement("div", { className: "associated-table-content" }, associatedList?.length
            ? associatedList?.map((order) => {
                return (React.createElement("div", { className: "row-content" },
                    React.createElement("div", { className: "content" }, order),
                    React.createElement(CnButton, { type: "primary", text: true, onClick: () => {
                            onVerify(order);
                        } }, $i18n.get({ id: '31255239516622848.CNTM', dm: '删除' }))));
            })
            : $i18n.get({
                id: 'NoAssociatedDeliveryDocumentForThe_161440634',
                dm: '暂无关联出库单',
            })))));
};
export default Associated;
