import $i18n from '@alife/panda-i18n';
import { CnAsyncSelect, CnButton, CnDialog, CnIcon, CnInput, CnMessage, Form, Table, } from '@alife/cn-ui';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import cnRequest from 'src/api';
import { B2bProgress } from '../biz-b2b-progress';
import { componentName } from './const';
// import * as _ from 'lodash';
import './index.scss';
const FormItem = Form.Item;
/**
 * 定制业务组件，无复用能力
 * 供 b2b拣选单查询 使用
 */
const B2bConfirm = ({ _context = {}, _dialogRef = {}, }) => {
    const currentRow = _context?.state?.CnTable?.__tableCurrentRow__ || {};
    const { pickBillId } = currentRow;
    const [workers, setWorkers] = useState([]);
    const [selectWorker, setSelectWorker] = useState('');
    const [reasons, setReasons] = useState([]);
    const [list, setList] = useState([]);
    const [fixHeaders, setFixHeaders] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const renderValue = useCallback((value, index, records) => {
        if (records.isSplit) {
            return '-';
        }
        return value;
    }, []);
    const renderReason = useCallback((value, index, record) => {
        if (record.isSplit) {
            return null;
        }
        return (React.createElement(CnAsyncSelect, { value: value, style: { width: '80px' }, dataSource: reasons, onChange: (v) => {
                setList(list.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            reason: v,
                        };
                    }
                    return item;
                }));
            } }));
    }, [reasons, list]);
    const renderBoxCode = useCallback((value, index, record) => {
        const uniqueBoxCodeList = record.uniqueBoxCodeList || [];
        // const selectOptions = uniqueBoxCodeList.map((item,i) => {
        //   return {
        //     label: item,
        //     value: i
        //   }
        // });
        if (record.hasPackage && record.diffQty > 0) {
            return (React.createElement(CnAsyncSelect, { value: value, mode: "multiple", hasSelectAll: true, showSearch: true, style: { width: '160px' }, dataSource: uniqueBoxCodeList, onChange: (v) => {
                    setList(list.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                lackPackageCodes: v,
                            };
                        }
                        return item;
                    }));
                } }));
        }
        // const listNew = list.map((item, i) => {
        //   if (i === index) {
        //     return {
        //       ...item,
        //       lackPackageCodes: [],
        //     };
        //   }
        //   return item;
        // });
        // console.log('=======',_.isEqual(listNew, list),listNew,list)
        // // if (!(_.isEqual(listNew, list))) {
        // //   setList(listNew);
        // // }
        // console.log('list ====', list);
        return null;
    }, [list]);
    const renderOperate = useCallback((value, index, record) => {
        if (record.isSplit) {
            return (React.createElement(CnButton, { disabled: record.hasPackage, onClick: () => {
                    setList(list.filter((_item, i) => i !== index));
                } },
                React.createElement(CnIcon, { type: "minus" })));
        }
        return (React.createElement(CnButton, { disabled: record.hasPackage, onClick: () => {
                const tmpList = [...list];
                tmpList.splice(index + 1, 0, {
                    ...record,
                    isSplit: true,
                    actualBoxQty: '',
                    actualPieceQty: '',
                    containerCode: '',
                    error: null,
                });
                setList(tmpList);
            } },
            React.createElement(CnIcon, { type: "add" })));
    }, [list]);
    const calculationDifference = useCallback((record, index) => {
        let all = 0;
        const plan = record.planQty;
        const newList = list.map((item, i) => {
            if (i === index) {
                return record;
            }
            return item;
        });
        if (record.packageNumber) {
            newList.forEach((item) => {
                if (item.pickBillDetailId === record.pickBillDetailId) {
                    all += Number(item.actualBoxQty * item.packageNumber + item.actualPieceQty);
                }
            });
            setList(newList.map((item, i) => {
                if (item.pickBillDetailId === record.pickBillDetailId) {
                    return {
                        ...(i === index ? record : item),
                        diffQty: plan - all,
                    };
                }
                return i === index ? record : item;
            }));
        }
        else {
            newList.forEach((item) => {
                if (item.pickBillDetailId === record.pickBillDetailId) {
                    all += Number(item.actualPieceQty);
                }
            });
            setList(newList.map((item, i) => {
                if (item.pickBillDetailId === record.pickBillDetailId) {
                    return {
                        ...(i === index ? record : item),
                        diffQty: plan - all,
                    };
                }
                return i === index ? record : item;
            }));
        }
    }, [list]);
    const onChangeShi = useCallback((record, key, value, index) => {
        if (value < 0)
            return;
        setTimeout(() => {
            calculationDifference({
                ...record,
                [key]: value,
            }, index);
        }, 0);
    }, [calculationDifference]);
    const onChangeContainer = useCallback((index, value) => {
        setList(list.map((item, idx) => {
            if (idx !== index) {
                return item;
            }
            return { ...item, containerCode: value };
        }));
    }, [list]);
    const headers = [
        ...fixHeaders?.map((item) => {
            let { width } = item;
            const { dataIndex, title } = item;
            if (width === undefined) {
                width = 90;
            }
            return {
                title,
                dataIndex,
                cell: renderValue,
                width,
            };
        }),
        !currentRow.ignoreContainer && {
            title: $i18n.get({ id: 'ContainerNumber', dm: '容器号' }),
            dataIndex: 'containerCode',
            width: 150,
            cell: (value, index) => {
                return (React.createElement(CnInput, { value: value, onChange: (val) => onChangeContainer(index, val), style: { width: '100px' }, width: "100" }));
            },
        },
        {
            title: $i18n.get({ id: 'PlannedQuantity', dm: '计划数量' }),
            width: 150,
            children: [
                {
                    title: $i18n.get({
                        id: 'TotalNumberOfPiecesNumber_955888411',
                        dm: '总件数=箱数+拆零数',
                    }),
                    cell: (__value, __index, record = {}) => {
                        const { isSplit, planQty, planBoxQty, planPieceQty } = record;
                        if (isSplit) {
                            return '-';
                        }
                        return (React.createElement("span", null,
                            React.createElement("span", { style: { marginRight: '10px' } }, planQty),
                            "=",
                            React.createElement("span", { style: { marginRight: '10px', marginLeft: '10px' } }, planBoxQty),
                            "+",
                            React.createElement("span", { style: { marginLeft: '10px', marginRight: '10px' } }, planPieceQty)));
                    },
                    width: 150,
                },
            ],
        },
        {
            title: $i18n.get({ id: 'ActualPickQuantity', dm: '实拣数量' }),
            children: [
                {
                    title: $i18n.get({
                        id: 'TotalNumberOfPiecesNumber_955888411',
                        dm: '总件数=箱数+拆零数',
                    }),
                    cell: (_value, index, record = {}) => {
                        const { packageNumber, actualBoxQty, actualPieceQty } = record;
                        return (React.createElement("span", { style: { display: 'flex', lineHeight: 1, alignItems: 'center' } },
                            React.createElement("span", { style: { marginRight: '10px', marginLeft: '3px' } }, packageNumber ? actualBoxQty * packageNumber + actualPieceQty : actualPieceQty),
                            React.createElement("span", { style: { padding: '0 5px' } }, "="),
                            React.createElement(CnInput, { disabled: !packageNumber, value: actualBoxQty, onChange: (v) => onChangeShi(record, 'actualBoxQty', v, index), htmlType: "number", trim: true, style: { flex: '2', width: '120px', marginLeft: '10px' } }),
                            React.createElement("span", { style: { padding: '0 5px' } }, "+"),
                            React.createElement(CnInput, { value: actualPieceQty, onChange: (v) => onChangeShi(record, 'actualPieceQty', v, index), htmlType: "number", trim: true, style: { flex: '2', width: '120px' } })));
                    },
                    dataIndex: 'actualBoxQty',
                    width: 360,
                },
            ],
        },
        {
            title: $i18n.get({ id: '31255238589681664.CNTM', dm: '差异数量' }),
            dataIndex: 'diffQty',
            cell: renderValue,
            width: 90,
        },
        {
            title: $i18n.get({ id: '31255238598070272.CNTM', dm: '差异原因' }),
            dataIndex: 'reason',
            cell: renderReason,
            width: 125,
        },
        {
            title: $i18n.get({ id: 'MissingBoxNumber', dm: '缺拣箱号' }),
            dataIndex: 'lackPackageCodes',
            cell: renderBoxCode,
            width: 185,
        },
        {
            title: $i18n.get({ id: 'Split', dm: '拆分' }),
            cell: renderOperate,
            width: 125,
            lock: 'right',
        },
    ];
    const getWorkers = useCallback(debounce((v) => {
        cnRequest({
            url: '/api/PC2BPickResource/queryOperator',
            method: 'post',
            data: {
                workNumber: v,
            },
        }).then((response) => {
            setWorkers(response?.data || []);
        });
    }, 1000), []);
    const getReasons = useCallback(() => {
        cnRequest({
            url: '/api/PC2BPickResource/queryDiffReason',
            method: 'post',
        }).then((response) => {
            setReasons(response?.data || []);
        });
    }, []);
    const getList = useCallback(() => {
        cnRequest({
            url: '/api/PC2BPickResource/queryPickConfirmDetail',
            method: 'post',
            data: {
                pickBillId,
            },
        }).then((response) => {
            const data = response?.data;
            setFixHeaders(data?.tableColumns || []);
            setList(data?.tableData || []);
        });
    }, []);
    const buildParams = useCallback(() => {
        const detailList = [];
        list
            .filter((pickitem) => pickitem.isSplit !== true)
            .forEach((pickitem) => {
            const { pickBillDetailId, packageNumber, reason } = pickitem;
            const containerItemList = [];
            let diffQty = '';
            list.forEach((item) => {
                if (item.pickBillDetailId === pickBillDetailId) {
                    diffQty = item.diffQty;
                    containerItemList.push({
                        containerCode: item.containerCode,
                        actualBoxQty: item.actualBoxQty,
                        actualPieceQty: item.actualPieceQty,
                        lackPackageCodes: item.hasPackage && item.diffQty > 0 ? item.lackPackageCodes : [],
                    });
                }
            });
            detailList.push({
                pickBillDetailId,
                containerItemList,
                diffReason: reason,
                packageNumber: packageNumber || 1,
                diffQty,
            });
        });
        const workerNo = selectWorker;
        const data = {
            workerNo,
            pickBillId,
            detailList,
            version: 1,
        };
        return data;
    }, [list, pickBillId, selectWorker]);
    const onConfirm = useCallback(() => {
        const data = buildParams();
        cnRequest({
            method: 'POST',
            url: '/api/PC2BPickResource/pickConfirm',
            data,
        }).then((resp) => {
            if (resp.success) {
                setShowProgress(true);
            }
        });
    }, [buildParams]);
    const handleOk = useCallback(() => {
        let isOk = true;
        if (!selectWorker) {
            CnMessage.error($i18n.get({ id: 'PleaseSelectTheOperator', dm: '请选择操作人' }));
            return;
        }
        list.forEach((item) => {
            if (!item.diffQty)
                item.diffQty = 0;
            if (item.diffQty < 0) {
                item.error = true;
                CnMessage.error($i18n.get({
                    id: 'ExceedingPlannedPickingQuantity',
                    dm: '超出计划拣选数量',
                }));
                isOk = false;
                return;
            }
            if (item.diffQty > 0) {
                item.error = true;
                isOk = false;
                CnDialog.confirm({
                    title: $i18n.get({ id: 'ThereAreDifferences', dm: '存在差异' }),
                    content: $i18n.get({
                        id: 'IsThereALackOfPicking_1408460566',
                        dm: '是否缺拣',
                    }),
                    onOk: () => onConfirm(),
                });
                return;
            }
            if (!currentRow.ignoreContainer) {
                if (!item.containerCode && (item.actualBoxQty > 0 || item.actualPieceQty > 0)) {
                    item.error = true;
                    CnMessage.error($i18n.get({
                        id: 'PleaseFillInTheContainerNumber_594731925',
                        dm: '请填写容器号',
                    }));
                    isOk = false;
                    return;
                }
            }
            item.error = null;
        });
        if (isOk) {
            onConfirm();
        }
    }, [list, selectWorker, onConfirm]);
    useEffect(() => {
        getReasons();
        getList();
    }, []);
    return (React.createElement("div", { className: "b2b-confirm" },
        React.createElement(Form, null,
            React.createElement(FormItem, { required: true, label: $i18n.get({ id: 'EnterOperator', dm: '录入操作人员' }) },
                React.createElement(CnAsyncSelect, { value: selectWorker, style: { width: '100%' }, name: "workerNo", showSearch: true, filterLocal: false, dataSource: workers, onSearch: (v) => getWorkers(v), onChange: (value) => {
                        setSelectWorker(value);
                    } }))),
        !showProgress && React.createElement(Table, { columns: headers, dataSource: list }),
        React.createElement("div", { className: "b2b-confirm-footer cn-dialog-alc-footer" },
            React.createElement(CnButton, { type: "secondary", onClick: () => {
                    _dialogRef?.close?.();
                } }, $i18n.get({ id: 'Cancel', dm: '取消' })),
            React.createElement(CnButton, { type: "primary", onClick: () => handleOk() }, $i18n.get({ id: 'Determine', dm: '确定' }))),
        showProgress && (React.createElement(B2bProgress, { _getContext: () => _context, onSuccess: () => {
                _dialogRef?.close?.();
            } }))));
};
B2bConfirm.displayName = componentName;
export { B2bConfirm };
