import $i18n from '@alife/panda-i18n';
import * as React from 'react';
import { CnButton, CnIcon } from '@alife/cn-ui';
import { cssPrefix } from './constant';
import PropTypes from 'prop-types';

export default function ImportButton(props) {
  const { children, onClick, buttonProps, buttonText, isMenuItem, disabled } = props;

  if (children) {
    return (
      <span className={`${cssPrefix}button-wrapper`} onClick={onClick}>
        {children}
      </span>
    );
  }

  return isMenuItem ? (
    <div
      {...buttonProps}
      onClick={onClick}
      style={
        disabled
          ? {
              display: 'flex',
              alignItems: 'center',
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : { display: 'flex', alignItems: 'center' }
      }
    >
      <CnIcon type="import-server" />
      {buttonText || $i18n.get({ id: 'Import', dm: '导入' })}
    </div>
  ) : (
    <CnButton
      {...buttonProps}
      iconSize="medium"
      icons={{
        loading: <CnIcon type="import-server" />,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText || $i18n.get({ id: 'Import', dm: '导入' })}
    </CnButton>
  );
}
ImportButton.displayName = 'ImportButton';

ImportButton.propTypes = {
  children: PropTypes.func,
  onClick: PropTypes.func,
  isMenuItem: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.objectOf([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};
