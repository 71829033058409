import React from 'react';
import { Icon, CnBalloon } from '@alife/cn-ui';
import { componentName } from './const';
import './index.scss';
const Tip = ({ content, color = '#ff8000', render, }) => {
    return content ? (React.createElement("div", { className: "cn-sc-tip" },
        React.createElement(CnBalloon.Tooltip, { align: "tl", trigger: React.createElement("span", null,
                React.createElement(Icon, { type: "prompt", size: "small", className: "action-area-tip-icon", style: { color } }),
                React.createElement("span", { className: "action-area-tip-info" }, content)) }, render ? render(content) : content))) : null;
};
Tip.displayName = componentName;
export { Tip };
