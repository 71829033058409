import $i18n from '@alife/panda-i18n';
import React from 'react';
import BatchInputBase from '@alife/cn-sc-batch-input';
const BatchInput = (prop) => {
    const { placeholder: placeholderInProps, maxCount } = prop;
    let placeholder = $i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' });
    if (placeholderInProps && placeholderInProps !== '请输入') {
        placeholder = placeholderInProps;
    }
    else if (maxCount) {
        placeholder = $i18n.get({
            id: 'UpToMaxCount',
            dm: '最多支持{maxCount}条',
        }, { maxCount });
    }
    return React.createElement(BatchInputBase, { ...prop, placeholder: placeholder });
};
BatchInput.displayName = 'BatchInput';
export { BatchInput };
