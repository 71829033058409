import $i18n from '@alife/panda-i18n';
import './index.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { CnTooltip, CnTag, CnIcon, CnButton, CnDialog } from '@alife/cn-ui';
import { componentName } from './const';
import { Sortable } from 'src/components/sortable';
import { useInit } from './hooks';

const TableTopSummary = (props) => {
  const {
    _getCurrentTableInfo,
    _getContext,
    associatedFilterId,
    enableTagClick = false,
  } = props || {};
  const content = useMemo(() => _getContext(), [_getContext]);
  const {
    isL2Env,
    summaryTip,
    isShowSumDom,
    summary,
    setSummary,
    activeTagKey,
    setActiveTagKey,
    handleClickTag,
    activeTagKeyRef,
    activeTagKeyRef4Query,
  } = useInit(_getCurrentTableInfo, enableTagClick, associatedFilterId, content);

  const [tmpSummary, setTmpSummary] = useState([]);
  const [visible, setVisible] = useState(false);

  const renderTag = useCallback(
    (tag) => {
      const { itemName, num, tips, ...other } = tag;
      let icon;

      if (tips) {
        icon = (
          <CnTooltip trigger={<CnIcon type="info-r" size="small" style={{ marginLeft: 4 }} />}>
            {tips}
          </CnTooltip>
        );
      }

      if (enableTagClick) {
        return (
          <div
            onClick={() => handleClickTag(tag?.key)}
            className={`${enableTagClick ? 'summary-v2-tag' : ''} ${
              activeTagKey === tag?.key ? 'active' : ''
            }`}
            {...other}
          >
            {itemName}
            {num ? `(${num})` : ''}
            {icon}
          </div>
        );
      }

      return (
        <CnTag status="info" {...other}>
          {itemName}: {num}
          {icon}
        </CnTag>
      );
    },
    [enableTagClick, activeTagKey, handleClickTag],
  );

  useEffect(() => {
    if (!enableTagClick || !content) {
      return;
    }

    // 拦截表格请求
    const cnFilter = content.$(`${associatedFilterId}`);
    const { _bindTable: associatedTableId } = cnFilter?.props || {};
    const cnTable = content.$(`${associatedTableId}`);
    const { remote } = cnTable?.props || {};
    const { url } = remote || {};

    if (!url || !window.__cn_request_instance?.request) {
      return;
    }

    window.__cn_request_instance?.request?.interceptors?.request?.use?.(async (r) => {
      const { url: requestUrl, headers } = r || {};

      if (requestUrl !== url || !enableTagClick) {
        return r;
      }

      if (activeTagKeyRef4Query.current) {
        headers['summary-active-key'] = activeTagKeyRef4Query.current || 'ALL';
        activeTagKeyRef4Query.current = null;
      } else if (activeTagKeyRef.current && window.event?.target?.closest?.('.cn-table-page')) {
        headers['summary-active-key'] = activeTagKeyRef.current || 'ALL';
      } else {
        setActiveTagKey('ALL');
      }

      return r;
    });
  }, [associatedFilterId, enableTagClick]);

  if (!isShowSumDom) {
    return null;
  }

  return (
    <div
      className={classnames('table-top-summary', {
        l2: isL2Env,
        'summary-v2-mode': enableTagClick,
      })}
    >
      <div className="panel-inline-title">
        <CnTooltip
          trigger={
            <CnButton type="primary" component="a" text className="panel-custom-habbit-config-btn">
              {enableTagClick ? (
                <CnIcon
                  type={enableTagClick ? 'icon-setting' : 'info-r'}
                  size="small"
                  onClick={() => {
                    if (enableTagClick) {
                      setTmpSummary(summary);
                      setVisible(true);
                    }
                  }}
                />
              ) : null}
              {$i18n.get({ id: 'SummaryData', dm: '汇总数据' })}
              <CnIcon type="info-r" size="small" />:
            </CnButton>
          }
          align="t"
        >
          {summaryTip}
        </CnTooltip>
      </div>
      <div className={`panel-inline-tags`}>
        {summary
          ?.filter((item) => item.visible !== false)
          ?.map((item, index) => {
            const { label = '', valueColor, value, valueType, unit } = item;

            let formattedValue =
              valueType === 'number' ? parseFloat(value).toLocaleString() : value;
            formattedValue = unit ? `${formattedValue}${unit}` : formattedValue;

            return renderTag({
              itemName: label,
              key: index,
              num: formattedValue,
              tips: item.tip || null,
              ...item,
              color: item.color || valueColor,
            });
          })}
      </div>

      <CnDialog
        className="habbit-edit-dialog"
        visible={visible}
        onOk={() => {
          setSummary(tmpSummary);
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        title={
          <div className="habbit-edit-dialog-title">
            <span className="habbit-edit-dialog-title-text">
              {$i18n.get({ id: 'CustomData', dm: '自定义数据' })}
            </span>
            <span className="habbit-edit-dialog-title-desc">
              {$i18n.get({
                id: 'ShowUpToTwoLinesOutsideTheSearch',
                dm: '(在搜索框外作为指标展示最多展示两行)',
              })}
            </span>
          </div>
        }
      >
        <Sortable adjustScale handle items={tmpSummary} setItems={setTmpSummary} />
      </CnDialog>
    </div>
  );
};

TableTopSummary.displayName = componentName;

export { TableTopSummary };
