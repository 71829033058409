import $i18n from '@alife/panda-i18n';
import { tarsUtil } from '@alife/cn-biz-utils-sc';
import { CnMessage } from '@alife/cn-ui';
import { domain as domainEnv } from './utils';

const habitKeyPrefix = 'cn_tars_export_template';
export const qlDomain =
  domainEnv.parseEnv(location.host) === 'pre' ? '//pre-ql.cainiao.com/' : '//ql.cainiao.com/';
const corsOpt = {
  method: 'post',
  mode: 'cors',
  credentials: 'include',
  headers: {
    'content-Type': 'application/json',
  },
};

export const getExportColumns = async (domain, exportMode, curTemlate) => {
  const res = await fetch(`${qlDomain}api/exportTask/queryExportInfo`, {
    ...corsOpt,
    body: JSON.stringify({
      pageCode: domain,
      exportMode,
      functionType: curTemlate.functionType,
      processType: curTemlate.processType,
      metricsType: curTemlate.metricsType,
      uuid: curTemlate.uuid,
    }),
  }).then((data) => data.json());

  // if (res?.success && res?.data && res?.data?.exportSelectFieldVOList) {
  //   setExportColumns(res.data.exportSelectFieldVOList);
  // }
  if (!res?.success) {
    CnMessage.show({
      type: 'error',
      content: res?.errorMsg || $i18n.get({ id: 'FailedToGetExportField', dm: '获取导出字段失败' }),
    });
    return null;
  }
  return ((res?.data && res?.data?.exportSelectFieldVOList) || []).map((e) => {
    return {
      key: e.fieldKey,
      name: e.fieldName,
      visible: true,
    };
  });
};

export async function getUserSet(domain, isDetail = false) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/listActions',
    method: 'GET',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
    },
    skipValidate: true,
  });

  if (!res?.success) {
    return null;
  }
  if (res.data && res.data.length > 0) {
    return res.data[0];
  }
  return null;
}

export async function addUserSet(domain, setList, isDetail = false) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/addAction',
    method: 'POST',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
      action: JSON.stringify(setList),
    },

    skipValidate: true,
  });

  const newId = res?.data?.index;
  return newId;
}

export async function updateUserSet(domain, setList, isDetail = false, actionId) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/updateAction',
    method: 'POST',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
      newAction: JSON.stringify(setList),
      actionId,
    },

    skipValidate: true,
  });

  if (res?.success) {
    return res.data;
  }
}

// ----- 模板相关接口 --------

export const getTemplateList = async (
  processType = 'package',
  metricsType = 'Filter',
  functionType,
) => {
  try {
    const res = await fetch(`${qlDomain}api/common/sdk/template/getAll`, {
      ...corsOpt,
      body: JSON.stringify({
        // searchKey: '模糊搜索', // 对应保存的模板的名字label
        functionType,
        processType,
        metricsType,
        paging: {
          currentPage: 1,
          pageSize: 999,
        },
      }),
    }).then((data) => data.json());

    if (!res?.success) {
      CnMessage.show({
        type: 'error',
        content:
          res?.errorMsg ||
          $i18n.get({
            id: 'FailedToQueryTemplateList',
            dm: '查询模板列表失败',
          }),
      });
      return null;
    }
    return res.data || [];
  } catch (e) {
    return Promise.resolve([]);
  }
};

export const addTemplate = async (domain, exportMode) => {
  const res = await fetch(`${qlDomain}/api/common/sdk/template/save`, {
    ...corsOpt,
    body: JSON.stringify({
      pageCode: domain,
      exportMode,
    }),
  }).then((data) => data.json());

  if (!res?.success) {
    CnMessage.show({
      type: 'error',
      content:
        res?.errorMsg || $i18n.get({ id: 'FailedToSaveNewTemplate', dm: '新增模板保存失败' }),
    });
    return null;
  }
  return true;
};

export const editTemplate = async (opt) => {
  try {
    const { processType, metricsType, functionType, uuid, templateName, exportColumns = [] } = opt;
    const postData = {
      processType,
      metricsType,
      functionType,
    };
    if (uuid) {
      postData.uuid = uuid;
    }
    postData.value = {
      label: templateName,
      data: exportColumns,
    };
    const res = await fetch(`${qlDomain}api/common/sdk/template/save`, {
      ...corsOpt,
      body: JSON.stringify(postData),
    }).then((data) => data.json());

    if (!res?.success) {
      CnMessage.show({
        type: 'error',
        content:
          res?.errorMsg ||
          $i18n.get({
            id: 'FailedToSaveUpdateTemplate',
            dm: '更新模板保存失败',
          }),
      });
      return null;
    }
    return true;
  } catch (e) {
    CnMessage.show({
      type: 'error',
      content: $i18n.get({
        id: 'FailedToSaveUpdateTemplate',
        dm: '更新模板保存失败',
      }),
    });
    return Promise.resolve(false);
  }
};

export const deleteTemplate = async (opt) => {
  try {
    // const { processType, metricsType, functionType, uuid } = opt;
    const res = await fetch(`${qlDomain}api/common/template/del`, {
      ...corsOpt,
      body: JSON.stringify(opt),
    }).then((data) => data.json());

    if (!res?.success) {
      CnMessage.show({
        type: 'error',
        content: res?.errorMsg || $i18n.get({ id: 'FailedToDeleteTemplate', dm: '删除模板失败' }),
      });
      return null;
    }
    return true;
  } catch (e) {
    CnMessage.show({
      type: 'error',
      content: $i18n.get({ id: 'FailedToDeleteTemplate', dm: '删除模板失败' }),
    });
    return Promise.resolve(false);
  }
};
