import $i18n from '@alife/panda-i18n';
import React from 'react';
// import PropTypes, { string } from 'prop-types';
import './index.scss';
import { CnInput, CnMessage, formilyReact } from '@alife/cn-ui';
import { showMultiItemsDialog } from './view';
// import request from './view/request';
import cnRequest from 'src/api';

// const DISABLE_DATA_FORMAT_FLAG = 'DISABLE_DATA_FORMAT_HASH_U896E';
const { useField } = formilyReact;
export default function MultiItems(props) {
  const {
    validateWhenOnblur,
    queryItemInfoParams,
    isMultiItemsParam,
    queryItemInfoURL,
    paramsListStructure,
    scene,
    onOk,
    handleIsNotMultiItems,
    ...rest
  } = props;

  const field = useField?.();

  const onBlur = (val) => {
    const blurValue = val?.target?.value;
    if (!val?.target?.value)
      CnMessage.warning($i18n.get({ id: 'PleaseEnterTheProductBarcode', dm: '请输入商品条码' }));
    if (validateWhenOnblur) {
      // 先校验，校验通过再继续执行
      const continueFlag = validateWhenOnblur(val?.target?.value, field);
      if (!continueFlag) return;
    }
    const params = queryItemInfoParams(val?.target?.value, field);
    const { method, DISABLE_DATA_FORMAT_HASH_U896E, ...queryItemInfoData } = params || {};
    cnRequest({
      url: queryItemInfoURL,
      method: method || 'post',
      withCredentials: true,
      data: DISABLE_DATA_FORMAT_HASH_U896E ? queryItemInfoData : { data: queryItemInfoData },
    }).then((res) => {
      const isMultiItems = isMultiItemsParam()?.reduce((k1, k2) => {
        return k1?.[k2];
      }, res?.data);
      const paramsList = paramsListStructure()?.reduce((k1, k2) => {
        return k1?.[k2];
      }, res?.data);

      // 不是一码多品
      if (!isMultiItems) {
        handleIsNotMultiItems && handleIsNotMultiItems(blurValue, field, res?.data);
        return;
      }

      // 是一码多品
      showMultiItemsDialog({
        scene,
        paramsList,
        itemBarCode: val?.target?.value,
        onOk: (rowData) => {
          onOk && onOk(blurValue, rowData, field);
        },
        onCancel: () => {},
        tableProps: {
          fixedHeader: false,
        },
      });
    });
  };

  return <CnInput {...rest} onBlur={onBlur} />;
}

export { MultiItems };

MultiItems.displayName = 'MultiItems';

MultiItems.propTypes = {
  // children: PropTypes.node,
  // type: string,
};
MultiItems.defaultProps = {
  // children: null,
  value: '',
  onChange: () => {},
  // type: 'select',
};
