import $i18n from '@alife/panda-i18n';
import React, { forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import { CnCheckbox, CnBalloon } from '@alife/cn-ui';
import { useSortable } from '@dnd-kit/sortable';

import Handle from './handle';
import './sort-item.scss';

const Item = React.memo(
  forwardRef(
    (
      {
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        label,
        checked,
        onChange,
        required,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return (
        <li
          className={classNames(
            'habbit-item-wrapper',
            fadeIn && 'fadeIn',
            sorting && 'sorting',
            dragOverlay && 'dragOverlay',
          )}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
            '--index': index,
          }}
          ref={ref}
        >
          <div
            className={classNames(
              'habbit-item',
              dragging && 'dragging',
              handle && 'withHandle',
              dragOverlay && 'dragOverlay',
              disabled && 'disabled',
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <span className="habbit-item-main">
              <CnCheckbox
                checked={required ? true : checked}
                disabled={required}
                onChange={onChange}
              />

              {required ? <span className="habbit-item-main-required">*</span> : null}
              <span
                className="habbit-item-main-label"
                onClick={() => {
                  !required && onChange?.(!checked);
                }}
              >
                <CnBalloon.Tooltip
                  trigger={
                    <span>
                      {index + 1}.{label}
                    </span>
                  }
                  closable={false}
                >
                  {label}
                  {required
                    ? $i18n.get({
                        id: 'RequiredNotToBeCanceled',
                        dm: '（必填不可取消）',
                      })
                    : null}
                </CnBalloon.Tooltip>
              </span>
            </span>
            {handle ? <Handle {...handleProps} {...listeners} /> : null}
          </div>
        </li>
      );
    },
  ),
);
export default Item;

export function SortableItem({
  disabled,
  animateLayoutChanges,
  getNewIndex,
  handle,
  id,
  label,
  index,
  onRemove,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
  checked,
  onChange,
  required,
}) {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  });

  return (
    <Item
      ref={setNodeRef}
      value={id}
      label={label}
      disabled={disabled}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      checked={checked}
      required={required}
      handleProps={
        handle
          ? {
              ref: setActivatorNodeRef,
            }
          : undefined
      }
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
      })}
      onRemove={onRemove ? () => onRemove(id) : undefined}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      onChange={onChange}
      {...attributes}
    />
  );
}
