import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { MenuButton, CnButton, CnDialog, Form, Field, CnInput, CnMessage, CnBalloon, Card, CnIcon, } from '@alife/cn-ui';
import { wmsCnRequestV3 } from '@alife/cn-biz-utils-sc';
import { domain } from './utils';
import zhCN from './zh-CN';
import { componentName } from './const';
import './index.scss';
const FormItem = Form.Item;
const formItemLayout = {
    wrapperCol: {
        span: 24,
    },
};
function Export(props) {
    const { locale = zhCN, buttons = ['exportAll'], selectedRowRecords = [], selectedRowKeys, _bindFilterData, params, className, popupClassName, name = locale.Export, footerActions = ['cancel', 'ok'], taskCenterUrl = 'https://data-wms.cainiao.com/main.html#/page/zreport/exportlist', customExportFn, isTaskMode = false, progressData, preCheck, csrfToken, isNeedCsrf, primaryKey = '', exportUrl = `${domain.getDomain('data')}/api/zreport/user/exportdata`, requestOptions, skipFilenameModes, csrfUrl, tip, isMenuItem = false, } = props;
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [exportType, setExportType] = useState('');
    const csrfId = useRef(null);
    const field = Field.useField();
    const getCsrf = () => {
        if (csrfId.current) {
            return Promise.resolve();
        }
        if (csrfToken) {
            csrfId.current = csrfToken;
            return Promise.resolve();
        }
        let origin = '';
        try {
            if (exportUrl) {
                if (exportUrl.indexOf('//') === 0) {
                    try {
                        const u = new URL(`https:${exportUrl}`);
                        origin = u.origin;
                    }
                    catch (e) {
                        //
                    }
                }
                else if (exportUrl.indexOf('http') === 0) {
                    try {
                        const u = new URL(exportUrl);
                        origin = u.origin;
                    }
                    catch (e) {
                        //
                    }
                }
                else if (exportUrl.indexOf('/') === 0) {
                    origin = location.origin;
                }
            }
            else {
                let tmpUrl = '';
                const tmpCsrfUrl = csrfUrl || `${domain.getDomain('data')}/api/csrf`;
                if (tmpCsrfUrl.indexOf('https:') === 0) {
                    tmpUrl = tmpCsrfUrl;
                }
                else if (tmpCsrfUrl.indexOf('http:') === 0) {
                    tmpUrl = tmpCsrfUrl.replace('http:', 'https:');
                }
                else if (tmpCsrfUrl.indexOf('//') === 0) {
                    tmpUrl = `https:${tmpCsrfUrl}`;
                }
                else if (tmpCsrfUrl.indexOf('/') === 0) {
                    tmpUrl = `${location.origin}${tmpCsrfUrl}`;
                }
                else {
                    tmpUrl = tmpCsrfUrl;
                }
                const tmp = new URL(tmpUrl);
                origin = tmp.origin;
            }
            return wmsCnRequestV3?.getCsrfId?.((token) => {
                if (token) {
                    csrfId.current = token;
                    return Promise.resolve();
                }
                return Promise.reject();
            }, origin);
        }
        catch (e) {
            return Promise.reject();
        }
    };
    const handleExport = (type) => {
        if (preCheck && !preCheck()) {
            return;
        }
        if (type === 'exportSelected') {
            setExportType(type);
            if (selectedRowRecords.length < 1) {
                CnMessage.warning(locale.PleaseRecord);
                return;
            }
        }
        else if (type === 'exportAll') {
            setExportType('exportAll');
        }
        else {
            setExportType('custom');
        }
        if (type && skipFilenameModes?.includes(type)) {
            onOk();
            return;
        }
        setVisible(true);
    };
    const getSelectedKeys = (recordKey, records) => {
        const selectedKeys = [];
        records.forEach((n) => {
            selectedKeys.push(n[recordKey]);
        });
        return selectedKeys;
    };
    const onOk = () => {
        const queryParams = {};
        let param = {};
        setLoading(true);
        field.validate((error, values) => {
            const { taskName } = values;
            if (!skipFilenameModes?.includes(exportType) && error) {
                setLoading(false);
                return;
            }
            if (customExportFn) {
                customExportFn(taskName, taskCenterUrl, exportType)
                    .then(() => {
                    setLoading(false);
                    setVisible(false);
                })
                    .catch(() => setLoading(false));
                return;
            }
            if (exportType === 'exportSelected') {
                if (selectedRowKeys && selectedRowKeys instanceof Array) {
                    selectedRowKeys.forEach(() => {
                        queryParams[primaryKey] = getSelectedKeys(primaryKey, selectedRowRecords);
                    });
                }
                else {
                    console.error('请配置selectedRowKeys属性');
                    return;
                }
            }
            else if (exportType === 'exportAll') {
                Object.assign(queryParams, _bindFilterData);
            }
            if (params && typeof params === 'object') {
                Object.assign(param, params);
            }
            param.exportName = taskName;
            if (progressData) {
                param = progressData(param, queryParams, exportType);
            }
            else {
                // 兼容老大宝逻辑
                param.type = params?.type === 'offline' ? 2 : 1;
                param.queryParams = queryParams;
            }
            const doExport = () => {
                fetch(exportUrl, {
                    method: 'post',
                    body: JSON.stringify({
                        data: param,
                        csrfId: csrfId.current,
                        _csrf: csrfId.current,
                    }),
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        'content-Type': 'application/json',
                    },
                    ...requestOptions,
                })
                    .then((res) => res.json())
                    .then((res) => {
                    if (res?.success) {
                        setVisible(false);
                        CnMessage.show({
                            type: 'success',
                            duration: 5000,
                            content: (React.createElement("span", { className: "task-center-notice" }, locale?.taskCreatedAndJump(React.createElement(CnButton, { text: true, component: "a", href: taskCenterUrl, target: "_blank", type: "primary" }, locale?.taskCenter)))),
                        });
                    }
                    else {
                        CnMessage.show({
                            type: 'error',
                            content: res?.error?.message || locale.ExportError,
                        });
                    }
                    setLoading(false);
                })
                    .catch((err) => {
                    let errorMessage = '';
                    if (err?.response) {
                        errorMessage = `${locale.SystemError} ${err.response.status} - ${err.response.statusText}`;
                    }
                    else {
                        errorMessage = err.message || locale.NetworkError;
                    }
                    CnMessage.show({
                        type: 'error',
                        content: errorMessage,
                    });
                })
                    .finally(() => {
                    setLoading(false);
                });
            };
            if (!isNeedCsrf) {
                doExport();
                return;
            }
            getCsrf()
                .then(() => doExport())
                .catch(() => setLoading(false));
        });
    };
    const renderButtons = () => {
        const menuItemTrigger = (React.createElement("div", { onClick: () => handleExport(buttons[0]), "data-hottag-name": "cn-sc-wms-biz-download" }, name));
        return (React.createElement(React.Fragment, null,
            buttons.length === 1 && !isMenuItem && (React.createElement(CnButton, { onClick: () => handleExport(buttons[0]), "data-hottag-name": "cn-sc-wms-biz-download" }, name)),
            buttons.length === 1 &&
                isMenuItem &&
                (tip ? (React.createElement(CnBalloon.Tooltip, { trigger: menuItemTrigger, triggerType: "hover" }, tip)) : (menuItemTrigger)),
            buttons.length > 1 && (React.createElement(MenuButton, { label: name },
                buttons
                    ?.filter((button) => typeof button !== 'string')
                    ?.map((button) => {
                    const { name: btnName, props: btnProps } = button;
                    return (React.createElement(MenuButton.Item, { "data-hottag-name": "cn-sc-wms-biz-download-custom", ...btnProps }, btnName));
                }),
                buttons.includes('exportSelected') && (React.createElement(MenuButton.Item, { onClick: () => handleExport('exportSelected'), "data-hottag-name": "cn-sc-wms-biz-download-selected" }, locale?.exportSelected)),
                buttons.includes('exportAll') && (React.createElement(MenuButton.Item, { onClick: () => handleExport('exportAll'), "data-hottag-name": "cn-sc-wms-biz-download-all" }, locale?.selectedAll)),
                buttons.includes('exportHistory') && taskCenterUrl && (React.createElement(MenuButton.Item, { "data-hottag-name": "cn-sc-wms-biz-download-link" },
                    React.createElement("a", { href: taskCenterUrl, target: "_blank", rel: "noreferrer" }, locale?.exportRecords)))))));
    };
    const renderTaskList = () => taskList?.map((task) => {
        const { status, fileName, retry, file } = task;
        return (React.createElement(Card, { free: true, style: { width: 200 } },
            React.createElement(Card.Header, { title: React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "cn-sc-card-header-text" },
                        React.createElement("i", { className: classnames('cn-sc-icon', `cn-sc-icon-${status}`) }),
                        React.createElement("span", null, {
                            loading: locale.Exporting,
                            success: locale?.successText,
                            failed: locale.failedText,
                        }[status])),
                    !!file && (React.createElement(CnButton, { className: "card-operate", text: true, component: "a", href: file, target: "_blank", type: "primary" }, locale?.download)),
                    retry && (React.createElement(CnButton, { className: "card-operate", text: true, component: "a", target: "_blank", type: "primary", onClick: () => retry() }, locale?.retry))), extra: React.createElement(CnIcon, { type: "close", onClick: () => setTaskList(taskList.filter((item) => item.id !== task.id)) }) }),
            React.createElement(Card.Content, null,
                React.createElement("div", { className: "filename" }, fileName))));
    });
    useEffect(() => {
        if (taskList?.length) {
            // todo
        }
    }, [taskList]);
    return (React.createElement("div", { style: { display: 'inline-block' } },
        React.createElement("div", { className: classnames('cn-sc-export', className) },
            !!taskList.length && isTaskMode && (React.createElement(CnBalloon.Tooltip
            // closable={false}
            , { 
                // closable={false}
                className: "cn-sc-export-balloon", 
                // visible={!!taskList?.length}
                align: "tr", trigger: renderButtons() }, renderTaskList())),
            !taskList.length && !isTaskMode && renderButtons()),
        React.createElement(CnDialog, { className: classnames('cn-sc-export-pop', popupClassName), visible: visible, onClose: () => setVisible(false), onCancel: () => setVisible(false), onOk: onOk, okProps: {
                loading,
            }, style: { width: 400 }, title: locale.FillTaskName, footerActions: footerActions },
            React.createElement(Form, { field: field, ...formItemLayout },
                React.createElement(FormItem, { hasFeedback: true, required: true, requiredMessage: locale.Mandatory },
                    React.createElement(CnInput, { name: "taskName", placeholder: locale.plsInput }))))));
}
Export.displayName = componentName;
export { Export };
