import React from 'react';
import classnames from 'classnames';
import { Loading } from '@alife/cn-ui';
import Code from 'src/common/code';
import './index.scss';
const DetailList = (props) => {
    const { columnNumbers = 3, data = null } = props;
    return !data ? (React.createElement(Loading, { className: "dynamic-page-table-loading" })) : (React.createElement("div", { className: "detail-list-content " }, new Array(columnNumbers).fill('').map((__unuse, index) => {
        const items = data
            ?.filter((_unuse, i) => i % columnNumbers === index)
            ?.map((item) => {
            const { url, value } = item || {};
            const v = typeof value === 'undefined' ? '-' : value;
            return (React.createElement("div", { className: "detail-list-content-item" },
                React.createElement("div", { className: "detail-list-content-item-label" }, item.label),
                React.createElement("div", { className: "detail-list-content-item-value" }, item?.render === 'code' ? (React.createElement(Code, { value: v, lenLimit: 20, href: url?.replace('order/search', 'page/order-detail') })) : (v))));
        });
        return (React.createElement("div", { className: classnames('detail-list-content-col', {
                'left-line': index > 0,
            }), style: { width: `${100 / 3}%` } }, items));
    })));
};
export default DetailList;
