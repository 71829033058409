import $i18n from '@alife/panda-i18n';
import { tarsUtil } from '@alife/cn-biz-utils-sc';
import { CnMessage } from '@alife/cn-ui';
import { domain as domainEnv } from './utils';

const habitKeyPrefix = 'cn_tars_export_template';

export const getExportColumns = async (domain, exportMode) => {
  const url =
    domainEnv.parseEnv(location.host) === 'pre' ? '//pre-ql.cainiao.com/' : '//ql.cainiao.com/';
  const res = await fetch(`${url}/api/exportTask/queryExportInfo`, {
    method: 'post',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'content-Type': 'application/json',
    },
    body: JSON.stringify({
      pageCode: domain,
      exportMode,
    }),
  }).then((data) => data.json());

  // if (res?.success && res?.data && res?.data?.exportSelectFieldVOList) {
  //   setExportColumns(res.data.exportSelectFieldVOList);
  // }
  if (!res?.success) {
    CnMessage.show({
      type: 'error',
      content: res?.errorMsg || $i18n.get({ id: 'FailedToGetExportField', dm: '获取导出字段失败' }),
    });
    return null;
  }
  return ((res?.data && res?.data?.exportSelectFieldVOList) || []).map((e) => {
    return {
      key: e.fieldKey,
      name: e.fieldName,
      visible: true,
    };
  });
};

export async function getUserSet(domain, isDetail = false) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/listActions',
    method: 'GET',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
    },
    skipValidate: true,
  });

  if (!res?.success) {
    return null;
  }
  if (res.data && res.data.length > 0) {
    return res.data[0];
  }
  return null;
}

export async function addUserSet(domain, setList, isDetail = false) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/addAction',
    method: 'POST',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
      action: JSON.stringify(setList),
    },

    skipValidate: true,
  });

  const newId = res?.data?.index;
  return newId;
}

export async function updateUserSet(domain, setList, isDetail = false, actionId) {
  const res = await tarsUtil.ajax({
    url: '/api/open/habit/updateAction',
    method: 'POST',
    data: {
      habitKey: `${habitKeyPrefix}_${domain}${isDetail ? '_detail' : ''}`,
      newAction: JSON.stringify(setList),
      actionId,
    },

    skipValidate: true,
  });

  if (res?.success) {
    return res.data;
  }
}
