import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  closestCenter,
  DragOverlay,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import Item, { SortableItem } from './sort-item';
import GridContainer from './grid-container';
import Wrapper from './wrapper';

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

/*
{
   name, 
   key, 
   visible, 
   required
}
*/

function Sortable(props) {
  const {
    activationConstraint,
    animateLayoutChanges,
    adjustScale = false,
    Container = GridContainer,
    collisionDetection = closestCenter,
    coordinateGetter = sortableKeyboardCoordinates,
    dropAnimation = dropAnimationConfig,
    getItemStyles = () => ({}),
    getNewIndex,
    handle = false,
    items,
    setItems,
    isDisabled = () => false,
    measuring,
    modifiers,
    removable,
    renderItem,
    reorderItems = arrayMove,
    strategy = rectSortingStrategy,
    style,
    useDragOverlay = true,
    wrapperStyle,
  } = props;

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      coordinateGetter,
    }),
  );
  const isFirstAnnouncement = useRef(true);
  const getIndexById = (id) => items.findIndex((item) => item.key === id);
  const activeIndex = activeId ? getIndexById(activeId) : -1;
  const handleRemove = removable
    ? (id) => setItems((prev) => prev.filter((item) => item !== id))
    : undefined;

  useEffect(() => {
    if (!activeId) {
      isFirstAnnouncement.current = true;
    }
  }, [activeId]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        setActiveId(null);

        if (over) {
          const overIndex = getIndexById(over.id);
          if (activeIndex !== overIndex) {
            setItems((prev) => reorderItems(prev, activeIndex, overIndex));
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      measuring={measuring}
      modifiers={modifiers}
    >
      <Wrapper style={style} center>
        <SortableContext items={items?.map?.((item) => item.key)} strategy={strategy}>
          <Container width={wrapperStyle?.()?.width}>
            {items.map((item, index) => {
              const { name, key, visible, required } = item;

              return (
                <SortableItem
                  key={key}
                  id={key}
                  label={name}
                  handle={handle}
                  index={index}
                  checked={visible}
                  required={required}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  disabled={isDisabled(key)}
                  renderItem={renderItem}
                  onRemove={handleRemove}
                  animateLayoutChanges={animateLayoutChanges}
                  useDragOverlay={useDragOverlay}
                  getNewIndex={getNewIndex}
                  onChange={(v) => {
                    setItems((prev) => {
                      return prev?.map?.((each, i) => {
                        if (i === index) {
                          return {
                            ...each,
                            visible: v,
                          };
                        }

                        return each;
                      });
                    });
                  }}
                />
              );
            })}
          </Container>
        </SortableContext>
      </Wrapper>
      {useDragOverlay
        ? createPortal(
            <DragOverlay
              adjustScale={adjustScale}
              dropAnimation={dropAnimation}
              style={{ zIndex: 1010 }}
            >
              {activeId ? (
                <Item
                  index={activeIndex}
                  required={items[activeIndex].required}
                  label={items[activeIndex].name}
                  value={items[activeIndex].key}
                  checked={items[activeIndex].visible}
                  handle={handle}
                  renderItem={renderItem}
                  dragOverlay
                  onChange={(v) => {
                    setItems((prev) => {
                      return prev?.map?.((each, i) => {
                        if (i === activeIndex) {
                          return {
                            ...each,
                            visible: v,
                          };
                        }

                        return each;
                      });
                    });
                  }}
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )
        : null}
    </DndContext>
  );
}

Sortable.displayName = 'Sortable';

export { Sortable };
export default Sortable;
