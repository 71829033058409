import $i18n from '@alife/panda-i18n';
import React, { useState } from 'react';
import { CnButton, CnDialog, Form, CnInput, CnNumberPicker, Field, CnMessage } from '@alife/cn-ui';
import cnRequest from 'src/api';
function Despatch(props) {
    const { _context, record, tableIds = [] } = props;
    const [tableId] = tableIds;
    const evField = Field.useField({
        values: {},
        autoValidate: true,
    });
    const weightField = Field.useField({
        values: {},
        autoValidate: true,
    });
    const weightCompanyField = Field.useField({
        values: {},
        autoValidate: true,
    });
    const [vehicleId, setVehicleId] = useState(null);
    const [stageVehicleId, setStageVehicleId] = useState(null); // 暂存派车单id
    const [showConfirmSendTransfer, setShowConfirmSendTransfer] = useState(false); // 确认发运弹窗显隐
    const [confirmSendTransferText, setConfirmSendTransferText] = useState(''); // 确认发运弹窗文案
    const [stageShowCodeDialog, setStageShowCodeDialog] = React.useState(false); // 暂存是否展示一维码
    const [visible, setVisible] = React.useState(false); // 是否展示一维码
    const [lstMessage, setLstMessage] = React.useState('');
    const [validationState, setValidationState] = React.useState(0); // 零售通调拨完整性校验验证状态
    const [integrityCheck, setIntegrityCheck] = React.useState(false); // 零售通发运完整性校验
    const [maxFloatedWeight, setMaxFloatedWeight] = React.useState(0); // 最大浮动
    const [minFloatedWeight, setMinFloatedWeight] = React.useState(0); // 最小浮动
    const [totalWeight, setTotalWeight] = React.useState(0); // 总重量
    // const [evCodeDialogVisible, setEvCodeDialogVisible] = useState(false);
    const [openWeightByScrappedCompanyDialog, setOpenWeightByScrappedCompanyDialog] = React.useState(false); // 是否展示填写报废公司称重重量以及报废单号
    const [openWeightByWarehouseDialog, setOpenWeightByWarehouseDialog] = React.useState(false); // 是否展示填写仓库称重重量 窗口
    const [evCode, setEvCode] = React.useState(''); // 一维码
    const [weightByWarehouse, setWeightByWarehouse] = React.useState();
    // 仓库称重重量
    // 是否需要发运确认
    const checkConfirmSendTransfer = (id) => {
        cnRequest({
            url: '/api/lp/vehicle/confirmSendTransfer',
            method: 'post',
            data: {
                id,
            },
        }).then((response) => {
            const { data } = response;
            setShowConfirmSendTransfer(data.showConfirmSendTransfer);
            setConfirmSendTransferText(data.confirmSendTransferText);
            setStageShowCodeDialog(data.showCodeDialog);
            if (!data?.showConfirmSendTransfer) {
                if (data?.showCodeDialog) {
                    setVisible(true);
                }
                else {
                    // 不需输入一维码，直接调用发运函数
                    sendTransfer(null, id, 0);
                }
                setShowConfirmSendTransfer(false);
            }
        });
    };
    /**
     * 调拨升级派车单发运
     * evCode 一维码
     * vehicleId 派车单id
     * validationState 零售通发运校验参数 ：默认需要输入0
     */
    const sendTransfer = function (code, id, validateResult) {
        cnRequest({
            url: '/api/lp/vehicle/sendTransfer',
            method: 'post',
            data: {
                id,
                evCode: code,
                validationState: validateResult,
            },
        }).then((response) => {
            const { data } = response; // 是否零售通数据完整性校验
            if (data.lstSuccess) {
                setLstMessage(data.message);
                setVisible(true);
                setIntegrityCheck(true);
                setValidationState(data.validationState);
                evField.reset(); // todo
                // 报废出库计划派车单流程改写
            }
            else if (data.checkSuccess) {
                setIntegrityCheck(false);
                setTotalWeight(data.totalWeight);
                setMinFloatedWeight(data.minFloatedWeight);
                setMaxFloatedWeight(data.maxFloatedWeight);
                setOpenWeightByWarehouseDialog(true);
            }
            else if (data.success) {
                CnMessage.success($i18n.get({ id: 'OperationSuccessful', dm: '操作成功' }));
                tableId && _context.$(tableId)?.remoteInstance?.load?.();
            }
        });
    };
    const renderEvCodeDialog = () => {
        // 一维码确认操作
        const okEvCode = () => {
            setEvCode(evField?.getValue('evCode'));
            setVisible(false);
            // 发运逻辑
            sendTransfer(evField?.getValue('evCode'), vehicleId, 0);
        };
        return (React.createElement(CnDialog, { closeMode: ['close', 'esc'], size: "small", visible: true, title: $i18n.get({ id: 'Shipment', dm: '发运' }), onOk: okEvCode, onCancel: () => {
                setVisible(false);
            }, onClose: () => {
                setShowConfirmSendTransfer(false);
            } },
            React.createElement(Form, { field: evField },
                React.createElement(Form.Item, { name: "evCode", label: $i18n.get({
                        id: 'PleaseEnterAOnedimensionalCode',
                        dm: '请输入一维码：',
                    }) },
                    React.createElement(CnInput, { placeholder: $i18n.get({
                            id: 'PleaseEnterAOnedimensionalCode_QSRYWM',
                            dm: '请输入一维码',
                        }) })))));
    };
    const renderWeightByWarehouseDialog = () => {
        // 仓库确认重量
        const openWeightByWarehouseConfirm = () => {
            const param = weightField?.getValues();
            const val = (param?.weightByWarehouse * 1000);
            if (maxFloatedWeight && maxFloatedWeight > 0 && minFloatedWeight && minFloatedWeight > 0) {
                const minValue = totalWeight - minFloatedWeight >= 0 ? totalWeight - minFloatedWeight : 0;
                const maxValue = totalWeight + maxFloatedWeight;
                if (val < minValue || val > maxValue) {
                    CnMessage.success($i18n.get({
                        id: 'TheFloatingValueLimitIsExceeded_348793866',
                        dm: '超出浮动值限制，请重新录入',
                    }));
                    return;
                }
            }
            setWeightByWarehouse(val);
            setOpenWeightByWarehouseDialog(false);
            setOpenWeightByScrappedCompanyDialog(true);
            weightField.reset();
        };
        return (React.createElement(CnDialog, { closeMode: ['close', 'esc'], size: "small", visible: true, title: $i18n.get({ id: 'EnterScrapDetails', dm: '录入报废详情' }), onOk: openWeightByWarehouseConfirm, onCancel: () => {
                setOpenWeightByWarehouseDialog(false);
            }, onClose: () => {
                setOpenWeightByWarehouseDialog(false);
            } },
            React.createElement(Form, { field: weightField },
                React.createElement(Form.Item, { name: "weightByWarehouse", label: $i18n.get({
                        id: 'PleaseFillInTheWarehouseWeighing_344389529',
                        dm: '请填写仓库称重重量（kg）：',
                    }) },
                    React.createElement(CnInput, { placeholder: $i18n.get({
                            id: 'PleaseEnterTheWeight',
                            dm: '请输入重量',
                        }) })))));
    };
    const renderWeightByScrappedCompanyDialog = () => {
        // 公司确认重量
        const openWeightByScrappedCompanyConfirm = () => {
            // const weightByWarehouse = that.state.weightByWarehouse;
            // const vehicleId = that.state.vehicleId;
            const param = weightCompanyField.getValues();
            const weightByScrappedCompany = param?.weightByScrappedCompany * 1000;
            const scrappedBillNo = param?.scrappedBillNo;
            setOpenWeightByScrappedCompanyDialog(false);
            weightCompanyField.reset();
            cnRequest({
                url: '/api/lp/vehicle/sendTransfer4ScrappedDetail',
                method: 'post',
                data: {
                    id: vehicleId,
                    weightByWarehouse,
                    weightByScrappedCompany,
                    scrappedBillNo,
                },
            }).then((response) => {
                const { data } = response; // 是否零售通数据完整性校验
                if (data.success) {
                    CnMessage.success($i18n.get({ id: 'OperationSuccessful', dm: '操作成功' }));
                    setOpenWeightByScrappedCompanyDialog(false);
                    weightCompanyField.reset();
                    tableId && _context.$(tableId)?.remoteInstance?.load?.();
                }
            });
        };
        return (React.createElement(CnDialog, { closeMode: ['close', 'esc'], size: "small", visible: true, title: $i18n.get({ id: 'EnterScrapDetails', dm: '录入报废详情' }), onOk: openWeightByScrappedCompanyConfirm, onCancel: () => {
                setOpenWeightByScrappedCompanyDialog(false);
            }, onClose: () => {
                setShowConfirmSendTransfer(false);
            } },
            React.createElement(Form, { field: weightCompanyField },
                React.createElement(Form.Item, { name: "weightByScrappedCompany", label: $i18n.get({
                        id: 'PleaseFillInTheScrapCompany_1355754478',
                        dm: '请填写报废公司称重重量(kg)：',
                    }) },
                    React.createElement(CnNumberPicker, { placeholder: $i18n.get({
                            id: 'PleaseFillInTheScrapCompany_1334621635',
                            dm: '请填写报废公司称重重量',
                        }) })),
                React.createElement(Form.Item, { name: "scrappedBillNo", label: $i18n.get({
                        id: 'ScrapCompanyScrapNumber',
                        dm: '报废公司报废单号：',
                    }) },
                    React.createElement(CnInput, { placeholder: $i18n.get({
                            id: 'PleaseEnterTheScrapCompanyScrap_1052620759',
                            dm: '请输入报废公司报废单号',
                        }) })))));
    };
    const renderShowConfirmSendTransfer = () => {
        // 确认发运
        const confirmSendTransfer = function () {
            if (stageShowCodeDialog) {
                setVisible(true);
                setShowConfirmSendTransfer(false);
            }
            else {
                // 不需输入一维码，直接调用发运函数
                sendTransfer(null, stageVehicleId, 0);
            }
        };
        return (React.createElement(CnDialog, { closeMode: ['close', 'esc'], size: "small", visible: true, title: $i18n.get({ id: 'ShippingConfirmation', dm: '发运确认' }), onOk: confirmSendTransfer, onCancel: () => {
                setShowConfirmSendTransfer(false);
            }, onClose: () => {
                setShowConfirmSendTransfer(false);
            } }, confirmSendTransferText + $i18n.get({ id: 'ConfirmShipment', dm: '确认发运？' })));
    };
    const renderIntegrityCheck = () => {
        // 零售通调拨发运完整性校验
        const okLstCheck = function () {
            setIntegrityCheck(false);
            // 执行发运逻辑
            sendTransfer(evCode, vehicleId, validationState);
        };
        return (React.createElement(CnDialog, { closeMode: ['close', 'esc'], size: "small", visible: true, title: $i18n.get({
                id: 'ShippingIntegrityCheck',
                dm: '发运完整性校验',
            }), onOk: okLstCheck, onCancel: () => {
                setIntegrityCheck(false);
            }, onClose: () => {
                setShowConfirmSendTransfer(false);
            } }, lstMessage));
    };
    const hanleDespatchClick = () => {
        // 将是否需要显示扫一维码做缓存
        setVehicleId(record.id);
        setStageVehicleId(record.id);
        setStageShowCodeDialog(record.showCodeDialog);
        checkConfirmSendTransfer(record.id);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CnButton, { onClick: hanleDespatchClick, text: true, component: 'a', type: "primary" }, $i18n.get({ id: 'Shipment', dm: '发运' })),
        visible && renderEvCodeDialog(),
        openWeightByWarehouseDialog && renderWeightByWarehouseDialog(),
        openWeightByScrappedCompanyDialog && renderWeightByScrappedCompanyDialog(),
        showConfirmSendTransfer && renderShowConfirmSendTransfer(),
        integrityCheck && renderIntegrityCheck()));
}
Despatch.displayName = 'Despatch';
export { Despatch };
