import $i18n from '@alife/panda-i18n';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { CnAsyncSelect, Form, CnDrawer, CnDialog, CnButton, CnTable, CnCard, CnMessage, CnNumberPicker, } from '@alife/cn-ui';
import cnRequest from 'src/api';
import { OwnerSelect } from '../owner-select';
import { componentName } from './const';
import './index.scss';
const columns = [
    {
        title: $i18n.get({ id: 'ParameterConfigurationItem', dm: '参数配置项' }),
        key: 'paramTypeName',
    },
    {
        title: $i18n.get({ id: 'ConfigurationResults', dm: '配置结果' }),
        key: 'propertyValues',
        width: 200,
        render: (value) => {
            if ((Array.isArray(value) && !value.length) || !value) {
                return '--';
            }
            return (React.createElement("div", null, value.map((item, index) => {
                const { keyValueName } = item;
                return (React.createElement("div", null,
                    index + 1,
                    "\u3001",
                    keyValueName));
            })));
        },
    },
    {
        title: $i18n.get({ id: 'EffectiveObject', dm: '生效对象' }),
        key: 'scopeValues',
        width: 150,
        render: (value) => {
            if ((Array.isArray(value) && !value.length) || !value) {
                return '--';
            }
            return (React.createElement("div", null, value.map((item) => {
                const { name, valueName } = item;
                return (React.createElement("div", null,
                    name,
                    valueName ? `/${valueName}` : '--'));
            })));
        },
    },
    {
        title: $i18n.get({ id: 'ParameterDescription', dm: '参数说明' }),
        key: 'remark',
    },
];
function BizParkConfig(props) {
    const { className = '', record, _context = {}, mode = 'new' } = props;
    const { paramTypeName, instanceId, paramTypeCode, remark, propertyValues, scopeValues } = record;
    const { laCode } = _context?.state?.CnFilter || {};
    const { token: csrfId = '' } = _context?.state?.csrf || {};
    const [value, setValue] = useState({});
    const [visible, setVisible] = useState(false);
    const [disableFlag] = useState(true);
    const [detailList, setDetailList] = useState([]);
    const buttonText = useMemo(() => {
        return {
            new: $i18n.get({ id: 'AddConfiguration', dm: '新增配置' }),
            edit: $i18n.get({ id: 'Edit', dm: '编辑' }),
            detail: $i18n.get({ id: '31255239428542464.CNTM', dm: '详情' }),
        }[mode];
    }, [mode]);
    const dialogTitle = useMemo(() => {
        const prefix = {
            new: $i18n.get({ id: 'New', dm: '新增' }),
            edit: $i18n.get({ id: 'Edit', dm: '编辑' }),
            detail: '',
        }[mode];
        const detailText = mode === 'detail' ? $i18n.get({ id: '31255239428542464.CNTM', dm: '详情' }) : '';
        const affix = paramTypeName ? `-${paramTypeName}${detailText}` : '';
        return $i18n.get({
            id: 'PrefixConfigurationAffix',
            dm: '{prefix}配置{affix}',
        }, { prefix, affix });
    }, [mode, paramTypeName]);
    const onOk = useCallback(() => {
        if (mode === 'detail') {
            setValue({});
            return setVisible(false);
        }
        const params = {
            laCode,
            paramTypeCode,
            propertyValues: (value.propertyValues || []).map((item) => {
                const { selectType, keyIndex, keyValue } = item;
                return {
                    keyValue: selectType === 'MULTI'
                        ? keyValue?.join(',') ?? ''
                        : keyValue ?? '',
                    keyIndex,
                };
            }),
            scopeValues: (value.scopeValues || []).map((item) => {
                const { values } = item;
                return {
                    ...item,
                    values: values || [],
                };
            }),
            warehouseId: window?.headerGlobalData?.getData('warehouseId') || '',
        };
        if (mode === 'edit' && instanceId) {
            params.instanceId = instanceId;
        }
        cnRequest({
            url: `/api/warehouseParam/${mode === 'new' ? 'createParam' : 'saveParam'}`,
            method: 'post',
            data: {
                csrfId,
                _csrf: csrfId,
                data: params,
            },
        })
            .then((response) => {
            if (response?.success) {
                CnMessage.success(mode === 'new'
                    ? $i18n.get({ id: 'CreationSuccessful', dm: '创建成功' })
                    : $i18n.get({ id: 'SavedSuccessfully', dm: '保存成功' }));
                setVisible(false);
                // 清空
                setValue({});
                if (mode === 'new') {
                    _context.$('node_oclc7bm6ti4')?.remoteInstance?.refresh?.();
                }
                _context.$('node_ocl4dw4gow2')?.remoteInstance?.refresh?.();
            }
        })
            .catch((e) => {
            CnMessage.error(e.message ||
                e.code ||
                $i18n.get({
                    id: 'ThereIsAnErrorPleaseTry_1625148378',
                    dm: '出错了，请稍后重试',
                }));
        });
    }, [value, laCode, paramTypeCode, csrfId, _context, mode, instanceId]);
    useEffect(() => {
        if (mode === 'new') {
            if (!visible) {
                return setDetailList([]);
            }
            cnRequest({
                url: '/api/warehouseParam/pageListParam',
                method: 'post',
                data: {
                    csrfId,
                    _csrf: csrfId,
                    data: {
                        laCode,
                        paramCode: paramTypeCode,
                        warehouseId: window?.headerGlobalData?.getData('warehouseId') || '',
                    },
                },
            })
                .then((response) => {
                if (response?.success) {
                    setDetailList(response?.data?.data || []);
                }
            })
                .catch((e) => {
                CnMessage.error(e.message ||
                    e.code ||
                    $i18n.get({
                        id: 'ThereIsAnErrorPleaseTry_1625148378',
                        dm: '出错了，请稍后重试',
                    }));
            });
        }
        if (mode !== 'new') {
            setValue({
                propertyValues: (propertyValues || [])?.map((propertyValue) => {
                    const { selectType, keyIndex } = propertyValue;
                    try {
                        return {
                            keyValue: selectType === 'MULTI'
                                ? propertyValue.keyValue.split(',')
                                : propertyValue.keyValue,
                            keyIndex,
                            selectType,
                        };
                    }
                    catch (e) {
                        return null;
                    }
                }),
                scopeValues: (scopeValues || [])?.map((scopeValue) => {
                    const { metaCode } = scopeValue;
                    const isOwnerSelect = metaCode === '2';
                    return {
                        ...scopeValue,
                        values: scopeValue.value?.split(',')?.map((v) => (isOwnerSelect ? parseInt(v, 10) : v)),
                    };
                }),
            });
        }
    }, [csrfId, laCode, paramTypeCode, visible, mode, propertyValues, scopeValues]);
    return (React.createElement("div", { "x-class": [className, 'biz-dialog-park-config'] },
        React.createElement(CnButton, { onClick: () => setVisible(true), text: true, component: "a", type: "primary" }, buttonText),
        (visible && mode !== 'detail' && (React.createElement(CnDialog, { className: "park-config-dialog", visible: true, onClose: () => {
                setValue({});
                setVisible(false);
            }, onCancel: () => {
                setValue({});
                setVisible(false);
            }, onOk: onOk, size: "medium", title: dialogTitle, footerActions: ['cancel', 'ok'] },
            React.createElement(Form, { value: value, useLabelForErrorMessage: true },
                mode === 'new' && detailList?.length ? (React.createElement(CnCard, { title: $i18n.get({
                        id: 'ConfiguredCondition',
                        dm: '已配置情况',
                    }) },
                    React.createElement(CnTable, { columns: columns, dataSource: detailList }))) : null,
                React.createElement(CnCard, { title: $i18n.get({
                        id: 'ParameterConfigurationItem',
                        dm: '参数配置项',
                    }) }, propertyValues?.map((item, index) => {
                    const { selectList, selectType, keyIndex, valueType } = item;
                    if (valueType === 'int') {
                        return (React.createElement(Form.Item, { label: item.name },
                            React.createElement(CnNumberPicker, { style: { width: '100%' }, value: value?.propertyValues?.[index]?.keyValue, disabled: false, onChange: (v) => {
                                    const tmpPropertyValues = value?.propertyValues || [];
                                    tmpPropertyValues[index] = { keyIndex, keyValue: v };
                                    setValue({
                                        ...value,
                                        propertyValues: tmpPropertyValues,
                                    });
                                } })));
                    }
                    return (React.createElement(Form.Item, { label: item.name },
                        React.createElement(CnAsyncSelect, { style: { width: '100%' }, value: value?.propertyValues?.[index]?.keyValue, disabled: false, onChange: (v) => {
                                const tmpPropertyValues = value?.propertyValues || [];
                                tmpPropertyValues[index] = {
                                    keyIndex,
                                    keyValue: v,
                                    selectType,
                                };
                                setValue({
                                    ...value,
                                    propertyValues: tmpPropertyValues,
                                });
                            }, dataSource: selectList?.map((selectItem) => {
                                return {
                                    label: selectItem.text,
                                    value: selectItem.value,
                                };
                            }), hasClear: true, mode: selectType === 'SINGLE' ? 'single' : 'multiple' })));
                })),
                scopeValues?.length ? (React.createElement(CnCard, { title: $i18n.get({ id: 'EffectiveObject', dm: '生效对象' }) }, scopeValues?.map((item, index) => {
                    const { selectList, metaCode, valueName, value: itemValue } = item;
                    const isOwnerSelect = metaCode === '2';
                    const tmpSelectList = selectList
                        ? selectList?.map((selectItem) => {
                            return {
                                label: selectItem.text,
                                value: selectItem.value,
                            };
                        })
                        : [
                            {
                                label: valueName,
                                value: isOwnerSelect ? parseInt(itemValue, 10) : itemValue,
                            },
                        ];
                    let content;
                    const tmpProps = {
                        style: { width: '100%' },
                        value: value?.scopeValues?.[index]?.values,
                        disabled: mode !== 'new',
                        onChange: (v) => {
                            const tmpScopeValues = value?.scopeValues || [];
                            tmpScopeValues[index] = {
                                code: metaCode,
                                values: v,
                            };
                            setValue({
                                ...value,
                                scopeValues: tmpScopeValues,
                            });
                        },
                        dataSource: tmpSelectList,
                        hasClear: true,
                        mode: 'multiple',
                    };
                    // 货主组件
                    if (isOwnerSelect) {
                        const ownerValue = (value?.scopeValues?.[index]?.values || []);
                        content = (React.createElement(OwnerSelect, { ...tmpProps, params: {
                                lastActiveOptions: mode !== 'new' && ownerValue
                                    ? encodeURIComponent(JSON.stringify(ownerValue?.map((v) => `${v}`)))
                                    : '',
                            } }));
                    }
                    else {
                        content = React.createElement(CnAsyncSelect, { ...tmpProps });
                    }
                    return (React.createElement(Form.Item, { label: item.name, name: item.name }, content));
                }))) : null,
                React.createElement(CnCard, { title: $i18n.get({ id: 'ParameterDescription', dm: '参数说明' }) }, remark))))) ||
            (visible && (React.createElement(CnDrawer, { className: "park-config-dialog-details", visible: true, onClose: () => {
                    setValue({});
                    setVisible(false);
                }, onCancel: () => {
                    setValue({});
                    setVisible(false);
                }, onOk: onOk, size: "medium", title: dialogTitle, footer: disableFlag },
                React.createElement(Form, { value: value, useLabelForErrorMessage: true },
                    React.createElement(CnCard, { title: $i18n.get({
                            id: 'ParameterConfigurationItem',
                            dm: '参数配置项',
                        }) }, propertyValues?.map((item, index) => {
                        const { selectList, selectType, keyIndex, valueType } = item;
                        if (valueType === 'int') {
                            return (React.createElement(Form.Item, { label: item.name },
                                React.createElement(CnNumberPicker, { style: { width: '100%' }, value: value?.propertyValues?.[index]?.keyValue, disabled: disableFlag, onChange: (v) => {
                                        const tmpPropertyValues = value?.propertyValues || [];
                                        tmpPropertyValues[index] = {
                                            keyIndex,
                                            keyValue: v,
                                        };
                                        setValue({
                                            ...value,
                                            propertyValues: tmpPropertyValues,
                                        });
                                    } })));
                        }
                        return (React.createElement(Form.Item, { label: item.name },
                            React.createElement(CnAsyncSelect, { style: { width: '100%' }, value: value?.propertyValues?.[index]?.keyValue, disabled: disableFlag, onChange: (v) => {
                                    const tmpPropertyValues = value?.propertyValues || [];
                                    tmpPropertyValues[index] = {
                                        keyIndex,
                                        keyValue: v,
                                        selectType,
                                    };
                                    setValue({
                                        ...value,
                                        propertyValues: tmpPropertyValues,
                                    });
                                }, dataSource: selectList?.map((selectItem) => {
                                    return {
                                        label: selectItem.text,
                                        value: selectItem.value,
                                    };
                                }), hasClear: true, mode: selectType === 'SINGLE' ? 'single' : 'multiple' })));
                    })),
                    scopeValues?.length ? (React.createElement(CnCard, { title: $i18n.get({ id: 'EffectiveObject', dm: '生效对象' }) }, scopeValues?.map((item, index) => {
                        const { selectList, metaCode, valueName, value: itemValue } = item;
                        const isOwnerSelect = metaCode === '2';
                        const tmpSelectList = selectList
                            ? selectList?.map((selectItem) => {
                                return {
                                    label: selectItem.text,
                                    value: selectItem.value,
                                };
                            })
                            : [
                                {
                                    label: valueName,
                                    value: isOwnerSelect ? parseInt(itemValue, 10) : itemValue,
                                },
                            ];
                        let content;
                        const tmpProps = {
                            style: { width: '100%' },
                            value: value?.scopeValues?.[index]?.values,
                            disabled: disableFlag,
                            onChange: (v) => {
                                const tmpScopeValues = value?.scopeValues || [];
                                tmpScopeValues[index] = {
                                    code: metaCode,
                                    values: v,
                                };
                                setValue({
                                    ...value,
                                    scopeValues: tmpScopeValues,
                                });
                            },
                            dataSource: tmpSelectList,
                            hasClear: true,
                            mode: 'multiple',
                        };
                        // 货主组件
                        if (isOwnerSelect) {
                            const ownerValue = (value?.scopeValues?.[index]?.values || []);
                            content = (React.createElement(OwnerSelect, { ...tmpProps, params: {
                                    lastActiveOptions: ownerValue
                                        ? encodeURIComponent(JSON.stringify(ownerValue?.map((v) => `${v}`)))
                                        : '',
                                } }));
                        }
                        else {
                            content = React.createElement(CnAsyncSelect, { ...tmpProps });
                        }
                        return (React.createElement(Form.Item, { label: item.name, name: item.name }, content));
                    }))) : null,
                    React.createElement(CnCard, { title: $i18n.get({
                            id: 'ParameterDescription',
                            dm: '参数说明',
                        }) }, remark)))))));
}
BizParkConfig.displayName = componentName;
export { BizParkConfig };
