const onlineHost = 'https://prod.wms.cainiao.com';
const preHost = 'https://pre-prod.wms.cainiao.com';
const dailyHost = 'https://wmsprod.daily.taobao.net';
const mockHost = 'https://oneapi.alibaba-inc.com/mock/cn-wms';
export function getHostName() {
    const { hostname } = location;
    if (location.href.indexOf('debugComponent=@alife/cn-sc-multi-items-operate') !== -1) {
        return mockHost;
    }
    if (hostname.indexOf('taobao.net') !== -1 || hostname.indexOf('cainiao.test') !== -1) {
        return dailyHost;
    }
    if (hostname.indexOf('pre.') !== -1 || hostname.indexOf('pre-') !== -1) {
        return preHost;
    }
    return onlineHost;
}
